<template>
  <div class="card p-shadow-6">
    <h1>Surveys</h1>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog />
    <W4Mstreak
      :states="streakStatus"
      v-on:W4MStreakChangeSelected="streakSelectedChange"
    />
    <SurveyChangeSurveySeller
      :showToast="true"
      :show="changeSeller.show"
      :patId="changeSeller.id"
      :currentSellerId="changeSeller.sellerId"
      v-on:cancel="changeSurveySellerCancel"
      v-on:setSurveySeller="changedSurveySeller"
    />
    <SurveyChangeDate
      :show="changeDate.show"
      :showToast="true"
      :patId="changeDate.patId"
      :patName="changeDate.patName"
      :patResume="changeDate.patResume"
      :patDate="changeDate.patDate"
      v-on:cancel="surveyChangeDateCancel"
      v-on:setDate="surveyChangeDateSubmit"
    />
    <SurveyChangeSurveyResponsible
      :showToast="true"
      :show="changeResponsible.show"
      :patId="changeResponsible.id"
      :currentRespId="changeResponsible.technicianUserId"
      v-on:cancel="changeSurveyResponsibleCancel"
      v-on:setSurveyResponsible="changedSurveyResponsible"
    />
    <SurveySetPendingStatus
      :showToast="true"
      :show="setStatusPending.show"
      :patId="setStatusPending.patId"
      :patName="setStatusPending.patName"
      v-on:cancel="setSurveyPendingCancel"
      v-on:setSurveyPending="setSurveyPending"
    />
    <SurveyRemovePendingStatus
      :showToast="true"
      :removePendingdStatus="removeStatusPending.show"
      :patId="removeStatusPending.patId"
      :patName="removeStatusPending.patName"
      v-on:cancel="removePendingStatusCancel"
      v-on:removePending="removePendingStatus"
    />
    <SurveySetRealizedStatus
      :showToast="true"
      :setConcluedStatus="setStatusRealized.show"
      :patId="setStatusRealized.patId"
      :patType="setStatusRealized.patType"
      :patName="setStatusRealized.patName"
      v-on:cancel="setRealizedCancel"
      v-on:setConclued="setRealizedOk"
    />
    <SurveyAddReport
      :showToast="true"
      :show="addSurveyReport.show"
      :patId="addSurveyReport.patId"
      :patName="addSurveyReport.name"
      :patReference="addSurveyReport.patReference"
      v-on:cancel="addReportCancel"
      v-on:addedReport="addedReport"
    />
    <SurveySetClosedStatus
      :showToast="true"
      :setClosedStatus="setSurveyClosed.show"
      :patId="setSurveyClosed.patId"
      :patService="setSurveyClosed.patType"
      :patName="setSurveyClosed.patName"
      v-on:cancel="concluedSurveyCancel"
      v-on:setClosed="concluedSurveyOk"
    />
    <SurveySetBudget
      :showToast="true"
      :changeBudgetedStatus="setSurveyBudget.show"
      :budgeted="setSurveyBudget.budgeted"
      :patType="setSurveyBudget.patType"
      :patId="setSurveyBudget.patId"
      :patName="setSurveyBudget.patName"
      v-on:cancel="setBudgetedCancel"
      v-on:setBudget="setBudgetedOk"
    />
    <SurveyChangeBudgetResponsible
      :showToast="true"
      :show="changeBudgetedResponsible.show"
      :patId="changeBudgetedResponsible.patId"
      :currentResponsibleId="changeBudgetedResponsible.currentRespId"
      v-on:cancel="changeBudgetResponsibleCancel"
      v-on:setSurveyBudgetResponsible="changedBudgetResponsible"
    />
    <SurveyMarkeIntervention
      :showToast="true"
      :show="setSurveyMarked.show"
      :patId="setSurveyMarked.patId"
      :patName="setSurveyMarked.patName"
      :patResume="setSurveyMarked.patResume"
      v-on:cancel="setMarkedCancel"
      v-on:marked="setMarkedOk"
    />
    <SurveyAddAttempt
      :showToast="true"
      :show="addScheduleAttempt.show"
      :patId="addScheduleAttempt.patId"
      :patName="addScheduleAttempt.patName"
      v-on:cancel="addAttemptScheduleCancel"
      v-on:addedAttemptSchedule="addAttemptScheduleOk"
    />
    <SurveySetCancelled
      :showToast="true"
      :show="setSurveyCanceled.show"
      :patId="setSurveyCanceled.patId"
      :patName="setSurveyCanceled.patName"
      v-on:cancel="setCanceledCancel"
      v-on:surveyCanceled="setCanceledOk"
    />
    <ProposalChangeResponsible
      :showToast="true"
      :show="proposalChangeResponsible.show"
      :proposalId="proposalChangeResponsible.proposalId"
      :proposalSubject="proposalChangeResponsible.proposalSubject"
      :currentTechnician="proposalChangeResponsible.technicianId"
      v-on:cancel="proChangeResposibleCancel"
      v-on:changeTechnicianResp="proChangeResposibleOk"
    />
    <ProposalSetBudget
      :showToast="true"
      :changeBudgetedStatus="setProposalBudget.show"
      :budgeted="setProposalBudget.budgeted"
      :proposalId="setProposalBudget.proposalId"
      :proposalName="setProposalBudget.proposalName"
      v-on:cancel="setProposalBudgetedCancel"
      v-on:setBudgetProposal="setProposalBudgetedOk"
    />

    <Dialog
      :visible.sync="comments.show"
      :style="{ width: $isMobile() ? '100vw' : '30vw' }"
      :header="
        comments.survey
          ? `Comentários do Survey ${comments.survey.id} - ${comments.survey.name}`
          : ''
      "
      :modal="true"
      :contentStyle="{ overflow: 'hide' }"
    >
      <DataTable
        :value="comments.comments"
        responsiveLayout="scroll"
        class="p-datatable-sm"
      >
        <Column field="comment" header="Comentário"></Column>
        <Column
          field="origin"
          header="Origem"
          :bodyStyle="{ 'text-align': 'center' }"
          :headerClass="'align-center'"
        >
          <template #body="slotProps">
            {{
              slotProps.data.origin == "installations"
                ? "Instalação"
                : slotProps.data.origin
            }}
          </template>
        </Column>
        <Column
          header="Criado"
          :bodyStyle="{ 'text-align': 'center' }"
          :headerClass="'align-center'"
        >
          <template #body="slotProps">
            {{ slotProps.data.created_at }}
            <br />
            {{ slotProps.data.created_by }}
          </template>
        </Column>
      </DataTable>
      <template #footer>
        <Button
          label="Fechar"
          icon="pi pi-times"
          class="p-button-sm p-button-danger"
          @click="cancelViewComments()"
        />
      </template>
    </Dialog>

    <Dialog
      :visible.sync="statusMenu.show"
      :style="{ width: $isMobile() ? '100vw' : '30vw' }"
      :header="
        statusMenu.survey
          ? `Alterar Estado do Survey ${statusMenu.survey.id} - ${statusMenu.survey.name}`
          : ''
      "
      :modal="true"
      :contentStyle="{ overflow: 'hide' }"
    >
      <div class="p-d-flex p-flex-wrap">
        <Button
          label="Pendente"
          icon="pi pi-hourglass"
          class="p-button-sm p-mt-1 p-mr-1"
          v-tooltip="'Alterar Estado para Pendente'"
          :style="getGroupheaderColor({ to_do: 'Pendente' })"
          @click="setPending(statusMenu.survey)"
        />
        <Button
          v-if="
            statusMenu.survey &&
            statusMenu.survey.to_do == 'Por Marcar (Contactar Gestor)'
          "
          label="Por Marcar"
          icon="pi pi-pause"
          class="p-button-sm p-mt-1 p-mr-1"
          v-tooltip="'Alterar Estado para Por Marcar'"
          :style="getGroupheaderColor({ to_do: 'Por Marcar' })"
          @click="setToMark(statusMenu.survey)"
        />
        <Button
          v-if="statusMenu.survey && statusMenu.survey.to_do == 'Por Realizar'"
          label="Realizado"
          icon="pi pi-check"
          class="p-button-sm p-button-success p-mt-1 p-mr-1"
          v-tooltip="
            'Marcar como Realizado e Alterar Estado para Sem Relatório'
          "
          :style="getGroupheaderColor({ to_do: 'Sem Relatório' })"
          @click="setRealized(statusMenu.survey)"
        />
        <Button
          v-if="
            statusMenu.survey &&
            (statusMenu.survey.to_do == 'Por Marcar' ||
              statusMenu.survey.to_do == 'Tentativa Marcação' ||
              statusMenu.survey.to_do == 'Por Marcar (Contactar Gestor)' ||
              statusMenu.survey.to_do == 'Sem Orçamento c/ Survey')
          "
          label="Marcar Survey"
          icon="pi pi-calendar"
          class="p-button-sm p-button-success p-mt-1 p-mr-1"
          v-tooltip="'Marcar Survey'"
          :style="getGroupheaderColor({ to_do: 'Marcado' })"
          @click="setMarked(statusMenu.survey)"
        />
        <Button
          v-if="
            statusMenu.survey &&
            statusMenu.survey.to_do == 'Sem Orçamento c/ Survey'
          "
          label="Marcar como Orçamentado"
          icon="pi pi-calendar"
          class="p-button-sm p-button-success p-mt-1 p-mr-1"
          v-tooltip="'Marcar como Orçamentado'"
          :style="getGroupheaderColor({ to_do: 'Sem Orçamento c/ Survey' })"
          @click="setBudget(statusMenu.survey)"
        />
        <Button
          v-if="
            statusMenu.survey &&
            (statusMenu.survey.to_do == 'Por Realizar' ||
              statusMenu.survey.to_do == 'Marcado')
          "
          label="Remarcar Survey"
          icon="pi pi-calendar"
          class="p-button-sm p-button-success p-mt-1 p-mr-1"
          v-tooltip="'Remarcar Survey'"
          :style="getGroupheaderColor({ to_do: 'Marcado' })"
          @click="setMarked(statusMenu.survey)"
        />

        <Button
          v-if="
            statusMenu.survey &&
            (statusMenu.survey.to_do == 'Tentativa Marcação' ||
              statusMenu.survey.to_do == 'Por Marcar')
          "
          label="Adicionar Tentativa de Marcação"
          icon="pi pi-phone"
          class="p-button-sm p-button-success p-mt-1 p-mr-1"
          :style="getGroupheaderColor({ to_do: 'Tentativa Marcação' })"
          @click="addAttemptSchedule(statusMenu.survey)"
        />
        <Button
          v-if="
            can('cancelSurveys') &&
            statusMenu.survey &&
            (statusMenu.survey.to_do == 'Tentativa Marcação' ||
              statusMenu.survey.to_do == 'Por Marcar (Contactar Gestor)' ||
              statusMenu.survey.to_do == 'Por Marcar')
          "
          label="Cancelar survey"
          icon="pi pi-ban"
          class="p-button-sm p-button-danger p-mt-1 p-mr-1"
          @click="setCanceled(statusMenu.survey)"
        />
      </div>
      <template #footer>
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-sm p-button-danger"
          @click="closeStatusMenu()"
        />
      </template>
    </Dialog>
    <div style="height: 65vh">
      <DataTable
        :value="surveysList"
        :filters.sync="filters"
        filterDisplay="row"
        :loading="loading"
        class="p-datatable-sm"
        :sortOrder="1"
        sortField="streakIndex"
        responsiveLayout="scroll"
        rowGroupMode="subheader"
        removableSort
        groupRowsBy="to_do"
        :expandableRowGroups="true"
        :expandedRowGroups.sync="expandedRowGroups"
        @rowgroup-expand="rowExpand"
        sortMode="single"
        :scrollable="true"
        scrollHeight="flex"
      >
        <template #header>
          <div class="table-header">
            <Button
              icon="pi pi-sync"
              class="p-button-sm"
              label="Mostrar todos os surveys"
              @click="showAllSurveys()"
            />
          </div>
        </template>
        <template #empty>
          Nada a mostrar. Selecione no menu em cima o que pretende visualizar.
        </template>
        <template #loading> A carregar dados. Por favor Aguarde... </template>
        <template #groupheader="slotProps">
          <div
            class="myGroupHeader"
            :style="getGroupheaderColor(slotProps.data)"
          >
            {{ slotProps.data.to_do }}
          </div>
        </template>
        <Column
          field="id"
          header="Pat"
          filterField="id"
          sortable
          :headerStyle="{ 'min-width': '6%', 'max-width': '6%' }"
          :filterHeaderStyle="{ 'min-width': '6%', 'max-width': '6%' }"
          :bodyStyle="{ 'min-width': '6%', 'max-width': '6%' }"
        >
          <template #body="slotProps">
            <span
              v-if="
                slotProps.data.itemType && slotProps.data.itemType == 'proposal'
              "
              class="clickable-item"
              v-tooltip="'Ver Proposta'"
              @click="viewProposal(slotProps.data.id)"
              >P{{ slotProps.data.id }}</span
            >
            <span
              v-else
              class="clickable-item"
              v-tooltip="'Ver Pat'"
              @click="viewPatInfo(slotProps.data.id)"
              >S{{ slotProps.data.id }}</span
            >
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          header="Estado"
          :headerStyle="{ 'min-width': '8%', 'max-width': '8%' }"
          :filterHeaderStyle="{ 'min-width': '8%', 'max-width': '8%' }"
          :bodyStyle="{ 'min-width': '8%', 'max-width': '8%' }"
        >
          <template #body="slotProps">
            <i
              class="pi pi-bookmark-fill streak-icon"
              :style="getIconColor(slotProps.data)"
            ></i>
            <span
              v-if="slotProps.data.to_do == 'Marcado'"
              :id="'multi-status-' + slotProps.data.id"
              class="clickable-item"
              v-tooltip="'Alterar Estado para Pendente'"
              @click="showStatusMenu(slotProps.data)"
              >{{ slotProps.data.to_do }}</span
            >
            <span
              v-else-if="
                slotProps.data.to_do == 'Por Marcar' ||
                slotProps.data.to_do == 'Por Realizar' ||
                slotProps.data.to_do == 'Por Marcar (Contactar Gestor)'
              "
              :id="'multi-status-' + slotProps.data.id"
              class="clickable-item"
              v-tooltip="'Alterar Estado'"
              @click="showStatusMenu(slotProps.data)"
              >{{ slotProps.data.to_do }}</span
            >
            <span
              v-else-if="slotProps.data.to_do == 'Tentativa Marcação'"
              :id="'multi-status-' + slotProps.data.id"
              class="clickable-item"
              v-tooltip="'Alterar Estado'"
              @click="showStatusMenu(slotProps.data)"
              >{{ numberAttemptSchedule(slotProps.data) }}</span
            >
            <span
              v-else-if="slotProps.data.status == '2. Pendente'"
              :id="'multi-status-' + slotProps.data.id"
              class="clickable-item"
              v-tooltip="'Remover Estado de Pendente'"
              @click="removePending(slotProps.data)"
              >{{ slotProps.data.to_do }}</span
            >
            <span
              v-else-if="slotProps.data.to_do == 'Sem Relatório'"
              :id="'multi-status-' + slotProps.data.id"
              class="clickable-item"
              v-tooltip="'Inserir Relatório'"
              @click="insertReport(slotProps.data)"
              >{{ slotProps.data.to_do }}</span
            >
            <span
              v-else-if="slotProps.data.to_do == 'Por Concluir'"
              :id="'multi-status-' + slotProps.data.id"
              class="clickable-item"
              v-tooltip="'Marcar como concluído'"
              @click="concluedSurvey(slotProps.data)"
              >{{ slotProps.data.to_do }}</span
            >
            <span
              v-else-if="slotProps.data.to_do == 'Sem Orçamento c/ Survey'"
              :id="'multi-status-' + slotProps.data.id"
              class="clickable-item"
              v-tooltip="'Marcar como Orçamentado'"
              @click="showStatusMenu(slotProps.data)"
              >{{ slotProps.data.to_do }}</span
            >
            <span
              v-else-if="slotProps.data.to_do == 'Sem Orçamento s/ Surveys'"
              :id="'multi-status-' + slotProps.data.id"
              class="clickable-item"
              v-tooltip="'Marcar como Orçamentado'"
              @click="setPropBudget(slotProps.data)"
              >{{ slotProps.data.to_do }}</span
            >
            <span v-else>{{ slotProps.data.to_do }}</span>
          </template>
        </Column>
        <Column
          field="name"
          header="Nome"
          filterField="name"
          :headerStyle="{ 'min-width': '20%', 'max-width': '20%' }"
          :filterHeaderStyle="{ 'min-width': '20%', 'max-width': '20%' }"
          :bodyStyle="{ 'min-width': '20%', 'max-width': '20%' }"
        >
          <template #body="slotProps">
            {{ slotProps.data.name }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="resume"
          header="Resumo"
          filterField="resume"
          :headerStyle="{ 'min-width': '15%', 'max-width': '15%' }"
          :filterHeaderStyle="{ 'min-width': '15%', 'max-width': '15%' }"
          :bodyStyle="{ 'min-width': '15%', 'max-width': '15%' }"
        >
          <template #body="slotProps">
            {{ slotProps.data.resume }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="seller"
          header="Vendedor"
          filterField="seller"
          :sortable="true"
          :headerStyle="{ 'min-width': '8.5%', 'max-width': '8.5%' }"
          :filterHeaderStyle="{ 'min-width': '8.5%', 'max-width': '8.5%' }"
          :bodyStyle="{ 'min-width': '8.5%', 'max-width': '8.5%' }"
        >
          <template #body="slotProps">
            <span
              v-if="
                slotProps.data.itemType && slotProps.data.itemType == 'proposal'
              "
              >{{ slotProps.data.seller }}</span
            >
            <span
              v-else-if="
                loggedUser.isEsa ||
                loggedUser.isAdmin ||
                loggedUser.id == slotProps.data.sellerId
              "
              class="clickable-item"
              v-tooltip="'Alterar Vendedor'"
              @click="changeSurveySeller(slotProps.data)"
              >{{ slotProps.data.seller }}</span
            >
            <span v-else>{{ slotProps.data.seller }}</span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="date"
          header="Survey Data"
          filterField="date_date"
          dataType="date"
          :headerStyle="{ 'min-width': '8.5%', 'max-width': '8.5%' }"
          :filterHeaderStyle="{ 'min-width': '8.5%', 'max-width': '8.5%' }"
          :bodyStyle="{ 'min-width': '8.5%', 'max-width': '8.5%' }"
        >
          <template #body="slotProps">
            <span
              v-if="
                slotProps.data.to_do == 'Por Realizar' ||
                slotProps.data.to_do == 'Por Marcar' ||
                slotProps.data.to_do == 'Marcado'
              "
              class="clickable-item"
              v-tooltip="'Alterar Data'"
              @click="changeSurveyDate(slotProps.data)"
              >{{ slotProps.data.date }}</span
            >
            <span v-else>{{ slotProps.data.date }}</span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <v-date-picker
              v-model="filterModel.value"
              @input="filterCallback()"
              :masks="{
                input: 'YYYY-MM-DD',
              }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="p-column-filter p-inputtext p-component"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
          </template>
        </Column>
        <Column
          field="technician"
          header="Téc. Resp. Survey"
          filterField="technician"
          :sortable="true"
          :headerStyle="{ 'min-width': '8.5%', 'max-width': '8.5%' }"
          :filterHeaderStyle="{ 'min-width': '8.5%', 'max-width': '8.5%' }"
          :bodyStyle="{ 'min-width': '8.5%', 'max-width': '8.5%' }"
        >
          <template #body="slotProps">
            <span
              v-if="
                slotProps.data.itemType && slotProps.data.itemType == 'proposal'
              "
            ></span>
            <span
              v-else-if="
                loggedUser.isEsa ||
                loggedUser.isAdmin ||
                loggedUser.id == slotProps.data.technicianUserId
              "
              class="clickable-item"
              v-tooltip="'Alterar Técnico Responsável'"
              @click="changeSurveyResposible(slotProps.data)"
              >{{
                slotProps.data.technician == ""
                  ? "_______"
                  : slotProps.data.technician
              }}</span
            >
            <span v-else>{{ slotProps.data.technician }}</span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="office"
          header="Zona"
          filterField="office"
          :headerStyle="{ 'min-width': '8.5%', 'max-width': '8.5%' }"
          :filterHeaderStyle="{ 'min-width': '8.5%', 'max-width': '8.5%' }"
          :bodyStyle="{ 'min-width': '8.5%', 'max-width': '8.5%' }"
        >
          <template #body="slotProps">
            {{ slotProps.data.office }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              v-model="filterModel.value"
              @input="filterCallback()"
              :options="[
                { zone: 'Faro', zoneValue: 'Faro' },
                { zone: 'Lisboa', zoneValue: 'Lisboa' },
                { zone: 'Porto', zoneValue: 'Porto' },
                { zone: 'Sem Zona', zoneValue: '___' },
              ]"
              optionValue="zoneValue"
              optionLabel="zone"
              class="p-column-filter"
              :showClear="true"
              :style="{
                width: '80%',
              }"
            >
            </Dropdown>
          </template>
        </Column>
        <Column
          field="hasComments"
          header="Comentários"
          filterField="hasComments"
          :headerClass="'align-center'"
          :headerStyle="{ 'min-width': '8.5%', 'max-width': '8.5%' }"
          :filterHeaderStyle="{ 'min-width': '8.5%', 'max-width': '8.5%' }"
          :bodyStyle="{
            'min-width': '8.5%',
            'max-width': '8.5%',
            'justify-content': 'center',
          }"
        >
          <template #body="slotProps">
            <i
              v-if="slotProps.data.hasComments"
              class="pi pi-comments clickable-item"
              v-tooltip="'Ver comentários'"
              @click="viewComments(slotProps.data)"
            ></i>
          </template>
        </Column>
        <Column
          field="userToDo"
          header="Resp. Orçamento"
          filterField="userToDo"
          :sortable="true"
          :headerStyle="{ 'min-width': '8.5%', 'max-width': '8.5%' }"
          :filterHeaderStyle="{ 'min-width': '8.5%', 'max-width': '8.5%' }"
          :bodyStyle="{ 'min-width': '8.5%', 'max-width': '8.5%' }"
          v-if="loggedUser.isEsa || can('seeBudgetSurveyResp')"
        >
          <template #body="slotProps">
            <span
              v-if="
                slotProps.data.itemType &&
                slotProps.data.itemType == 'proposal' &&
                (loggedUser.isEsa ||
                  loggedUser.id == slotProps.data.assigned_to)
              "
              class="clickable-item"
              v-tooltip="'Alterar Técnico Responsável'"
              @click="proChangeResposible(slotProps.data)"
              >{{
                !slotProps.data.assigned_to
                  ? "_______"
                  : slotProps.data.proposal_assigned_user.username
              }}</span
            >
            <span
              v-else-if="
                loggedUser.isEsa || loggedUser.id == slotProps.data.sellerId
              "
              class="clickable-item"
              v-tooltip.left="'Alterar Responsável pelo Orçamento'"
              @click="changeSurveyRespBudgey(slotProps.data)"
              >{{
                slotProps.data.userToDo == null
                  ? "_______"
                  : slotProps.data.userToDo
              }}</span
            >
            <span v-else>{{ slotProps.data.userToDo }}</span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
// import filesService from "../services/files.service";
import W4Mstreak from "../components/W4Mstreak/W4Mstreak.vue";
import SurveyChangeSurveySeller from "../components/SurveyChangeSurveySeller";
import SurveyChangeDate from "../components/SurveyChangeDate";
import SurveyChangeSurveyResponsible from "../components/SurveyChangeSurveyResponsible";
import SurveySetPendingStatus from "../components/SurveySetPendingStatus";
import SurveyRemovePendingStatus from "../components/SurveyRemovePendingStatus";
import SurveySetRealizedStatus from "../components/SurveySetConcluedStatus.vue";
import SurveySetClosedStatus from "../components/SurveySetClosedStatus";
import SurveyAddReport from "../components/SurveyAddReport";
import SurveySetBudget from "../components/SurveySetBudget";
import SurveyChangeBudgetResponsible from "../components/SurveyChangeBudgetResponsible";
import SurveyMarkeIntervention from "../components/SurveyMarkIntervention.vue";
import SurveyAddAttempt from "../components/SurveyAddAttempt.vue";
import SurveySetCancelled from "../components/SurveySetCanceled.vue";
import surveysService from "../services/surveys.service";
import proposalService from "../services/proposal.service";
import ProposalChangeResponsible from "../components/proposal/ChangeResponsible.vue";
import ProposalSetBudget from "../components/proposal/SetBudget.vue";
import { FilterMatchMode } from "primevue/api/";

export default {
  name: "Surveys",
  components: {
    W4Mstreak,
    SurveyChangeSurveySeller,
    SurveyChangeDate,
    SurveyChangeSurveyResponsible,
    SurveySetPendingStatus,
    SurveyRemovePendingStatus,
    SurveySetRealizedStatus,
    SurveyAddReport,
    SurveySetClosedStatus,
    SurveySetBudget,
    SurveyChangeBudgetResponsible,
    SurveyMarkeIntervention,
    SurveyAddAttempt,
    SurveySetCancelled,
    ProposalChangeResponsible,
    ProposalSetBudget,
  },
  data() {
    return {
      streakStatus: [],
      surveysList: [],
      expandedRowGroups: [],
      loading: false,
      filters: {
        id: { value: null, matchMode: FilterMatchMode.EQUALS },
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        date_date: { value: null, matchMode: FilterMatchMode.DATE_IS },
        resume: { value: null, matchMode: FilterMatchMode.CONTAINS },
        seller: { value: null, matchMode: FilterMatchMode.CONTAINS },
        technician: { value: null, matchMode: FilterMatchMode.CONTAINS },
        userToDo: { value: null, matchMode: FilterMatchMode.CONTAINS },
        to_do: { value: null, matchMode: FilterMatchMode.EQUALS },
        office: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
      changeSeller: { show: false, id: null, sellerId: 0 },
      changeResponsible: { show: false, id: null, technicianUserId: 0 },
      changeDate: {
        show: false,
        patId: null,
        patName: null,
        patResume: null,
        patDate: null,
      },
      statusMenu: { show: false, survey: null },
      setStatusPending: { show: false, patId: null, patName: null },
      removeStatusPending: { show: false, patId: null, patName: null },
      setStatusRealized: {
        show: false,
        patId: null,
        patType: "Survey",
        patName: null,
      },
      addSurveyReport: {
        show: false,
        patId: null,
        patName: null,
        patReference: null,
      },
      setSurveyClosed: {
        show: false,
        patId: null,
        patType: "Survey",
        patName: null,
      },
      setSurveyBudget: {
        show: false,
        patId: null,
        budgeted: null,
        patType: "Survey",
        patName: null,
      },
      changeBudgetedResponsible: {
        show: false,
        patId: null,
        currentRespId: null,
      },
      setSurveyMarked: {
        show: false,
        patId: null,
        patName: null,
        patResume: null,
      },
      setSurveyCanceled: {
        show: false,
        patId: null,
        patName: null,
      },
      addScheduleAttempt: { show: false, patId: null, patName: null },
      comments: { show: false, survey: null, comments: [] },
      proposalChangeResponsible: {
        show: false,
        proposalId: null,
        technicianId: null,
        proposalSubject: null,
      },
      setProposalBudget: {
        show: false,
        proposalId: null,
        budgeted: null,
        proposalName: null,
      },
    };
  },
  computed: {
    loggedUser() {
      return this.$store.state.auth.user;
    },
  },
  async beforeCreate() {},
  async created() {
    if (this.$route.params.status) {
      if (this.$route.params.status === "Sem Orçamento c/ Survey") {
        this.filters.userToDo.value = this.loggedUser.username;
      } else {
        this.filters.technician.value = this.loggedUser.username;
      }
    }
    await this.initStreakStatus();
  },
  methods: {
    async initStreakStatus() {
      this.loading = true;
      let toMarkContactManager =
        await surveysService.countToMarkContactManager();
      let toMark = await surveysService.countToMark();
      let attemptSchedule = await surveysService.countAttemptSchedule();
      let scheduled = await surveysService.countScheduled();
      let pending = await surveysService.countPending();
      let unfinished = await surveysService.countUnfinished();
      let withoutReport = await surveysService.countWithoutReport();

      if (
        this.can("seeProposalsInSurveysList") ||
        this.$store.state.auth.user.isEsa
      ) {
        let pendingProposal = await proposalService.countByStatus(2);
        pending.totalSurveysPending += pendingProposal;
        pending.userSurveysPending += pendingProposal;
      }
      let activeStatus = this.$route.params.status
        ? this.$route.params.status
        : "";
      this.streakStatus = [
        {
          backgroudColor: "#FFA100",
          textColor: "#000",
          title: "Por Marcar",
          counter:
            this.loggedUser.isEsa || this.loggedUser.isAdmin
              ? toMark.totalSurveysToMark
              : toMark.userSurveysToMark,
          active: activeStatus === "Por Marcar",
        },
        {
          backgroudColor: "#FCC200",
          textColor: "#000",
          title: "Tentativa Marcação",
          counter:
            this.loggedUser.isEsa || this.loggedUser.isAdmin
              ? attemptSchedule.totalSurveysAttemptSchedule
              : attemptSchedule.userSurveysAttemptSchedule,
          active: activeStatus === "Tentativa Marcação",
        },
        {
          backgroudColor: "#E1C800",
          textColor: "#000",
          title: "Por Marcar (Contactar Gestor)",
          counter:
            this.loggedUser.isEsa || this.loggedUser.isAdmin
              ? toMarkContactManager.totalSurveysToMark
              : toMarkContactManager.userSurveysToMark,
          active: activeStatus === "Por Marcar (Contactar Gestor)",
          data: [],
        },
        {
          backgroudColor: "#8E9BEF",
          textColor: "#FFF",
          title: "Marcado",
          counter:
            this.loggedUser.isEsa || this.loggedUser.isAdmin
              ? scheduled.totalSurveysScheduled
              : scheduled.userSurveysScheduled,
          active: activeStatus === "Marcado",
        },
        {
          backgroudColor: "#5F78DA",
          textColor: "#FFF",
          title: "Pendente",
          counter:
            this.loggedUser.isEsa || this.loggedUser.isAdmin
              ? pending.totalSurveysPending
              : pending.userSurveysPending,
          active: activeStatus === "Pendente",
        },
        {
          backgroudColor: "#4455BD",
          textColor: "#FFF",
          title: "Por Realizar",
          counter:
            this.loggedUser.isEsa || this.loggedUser.isAdmin
              ? unfinished.totalSurveysUnfinished
              : unfinished.userSurveysUnfinished,
          active: activeStatus === "Por Realizar",
        },
        {
          backgroudColor: "#191D80",
          textColor: "#FFF",
          title: "Sem Relatório",
          counter:
            this.loggedUser.isEsa || this.loggedUser.isAdmin
              ? withoutReport.totalSurveysWithoutReport
              : withoutReport.userSurveysWithoutReport,
          active: activeStatus === "Sem Relatório",
        },
      ];

      if (
        this.can("seeBudgetSurveyResp") ||
        this.$store.state.auth.user.isEsa
      ) {
        /*let unclosed = await surveysService.countUnclosed();
        this.streakStatus.push({
          backgroudColor: "#00A2B3",
          textColor: "#FFF",
          title: "Por Concluir",
          counter: unclosed.totalSurveysUnclosed,
          active: activeStatus === "Por Concluir",
        });*/
        // this.streakStatus.push({
        //   backgroudColor: "#00C8B6",
        //   textColor: "#FFF",
        //   title: "Concluido",
        //   counter: 0,
        //   active: false,
        // });
        let withoutBudget = await surveysService.countWithoutBudget();
        this.streakStatus.push({
          backgroudColor: "#005B94",
          textColor: "#FFF",
          title: "Sem Orçamento c/ Survey",
          counter:
            withoutBudget.totalSurveyswithoutBudget == undefined
              ? withoutBudget.userSurveyswithoutBudget
              : withoutBudget.totalSurveyswithoutBudget,
          active: activeStatus === "Sem Orçamento c/ Survey",
        });
      }

      if (
        this.can("seeProposalsInSurveysList") ||
        this.$store.state.auth.user.isEsa
      ) {
        let proposalAwaitResponse = await proposalService.countByStatus(1);
        this.streakStatus.unshift({
          backgroudColor: "#a5aa36",
          textColor: "#000",
          title: "Aguarda Resposta",
          counter: proposalAwaitResponse,
          active: activeStatus === "Aguarda Resposta",
        });

        let proposalOpenAndClassified =
          await proposalService.countOpenAndClassified();
        this.streakStatus.unshift({
          backgroudColor: "#c0c942",
          textColor: "#000",
          title: "Sem Pat",
          counter: proposalOpenAndClassified,
          active: activeStatus === "Sem Pat",
        });
      }

      if (
        this.can("seeSurveyProporsalWithoutClassification") ||
        this.$store.state.auth.user.isEsa
      ) {
        let WithoutClassification =
          await proposalService.countWithoutClassification();
        this.streakStatus.unshift({
          backgroudColor: "#d38200",
          textColor: "#FFF",
          title: "Por Classificar",
          counter: WithoutClassification,
          active: activeStatus === "Por Classificar",
        });
      }

      if (
        this.can("seeBudgetSurveyProporsal") ||
        this.$store.state.auth.user.isEsa
      ) {
        let proporsalWithoutBudget = await proposalService.countUnbudgeted();
        this.streakStatus.push({
          backgroudColor: "#00b6bc",
          textColor: "#FFF",
          title: "Sem Orçamento s/ Surveys",
          counter: proporsalWithoutBudget,
          active: activeStatus === "Sem Orçamento s/ Surveys",
        });
      }

      if (activeStatus != "") {
        let activeStatusIndex = this.getStreakStatusIndex(activeStatus);
        if (activeStatusIndex >= 0) {
          let streakInfo = {
            currentStateIndex: activeStatusIndex,
            currentState: activeStatus,
          };
          this.streakSelectedChange(streakInfo);
        }
      }
      return (this.loading = false);
    },
    getGroupheaderColor(info) {
      let statusInfo = this.streakStatus.find(
        (status) => status.title == info.to_do
      );
      if (!statusInfo) {
        return {};
      }
      return {
        backgroundColor: statusInfo.backgroudColor,
        borderColor: statusInfo.backgroudColor,
        color: statusInfo.textColor,
      };
    },
    getIconColor(info) {
      let statusInfo = this.streakStatus.find(
        (status) => status.title == info.to_do
      );
      return { color: statusInfo.backgroudColor };
    },
    rowExpand(event) {
      let streakInfo = {
        currentStateIndex: this.getStreakStatusIndex(event.data),
        currentState: event.data,
      };
      return this.streakSelectedChange(streakInfo);
    },
    async showAllSurveys() {
      this.surveysList = [];
      for (const [index, status] of this.streakStatus.entries()) {
        status.loaded = false;
        await this.streakSelectedChange({
          currentStateIndex: index,
          currentState: status.title,
        });
      }
    },
    getStreakStatusIndex(status) {
      return this.streakStatus.findIndex(
        (streakItem) => streakItem.title == status
      );
    },
    streakStatusChange(from, to) {
      let statusFrom = this.streakStatus.find((status) => status.title == from);
      if (statusFrom) {
        statusFrom.counter = --statusFrom.counter;
      }
      let statusTo = this.streakStatus.find((status) => status.title == to);
      if (statusTo) {
        statusTo.counter = ++statusTo.counter;
      }
    },
    removeByStatus(status) {
      this.surveysList.forEach((survey, index, object) => {
        if (survey.to_do === status) {
          object.splice(index, 1);
        }
      });
    },
    streakSelectedChange(selected) {
      if (
        this.streakStatus[selected.currentStateIndex].loaded != undefined &&
        this.streakStatus[selected.currentStateIndex].loaded == true
      ) {
        return;
      }
      this.streakStatus[selected.currentStateIndex].loaded = true;
      this.removeByStatus(selected.currentState);
      switch (selected.currentState) {
        case "Por Marcar (Contactar Gestor)":
          this.getToMarkContactManager(selected.currentStateIndex);
          break;
        case "Por Marcar":
          this.getToMark(selected.currentStateIndex);
          break;
        case "Tentativa Marcação":
          this.getAttemptSchedule(selected.currentStateIndex);
          break;
        case "Marcado":
          this.getScheduled(selected.currentStateIndex);
          break;
        case "Pendente":
          this.getPending(selected.currentStateIndex);
          break;
        case "Por Realizar":
          this.getUnfinished(selected.currentStateIndex);
          break;
        case "Sem Relatório":
          this.getWithoutReport(selected.currentStateIndex);
          break;
        case "Por Concluir":
          this.getUnclosed(selected.currentStateIndex);
          break;
        case "Sem Orçamento c/ Survey":
          this.getWithoutBudget(selected.currentStateIndex);
          break;
        case "Sem Orçamento s/ Surveys":
          this.proposalUnbudgeted(selected.currentStateIndex);
          break;
        case "Por Classificar":
          this.proposalWithoutClassification(selected.currentStateIndex);
          break;
        case "Sem Pat":
          this.proposalOpenAndClassified(selected.currentStateIndex);
          break;
        case "Aguarda Resposta":
          this.proposalByStatus(
            selected.currentStateIndex,
            1,
            selected.currentState
          );
          break;
        // case "Propostas Pendentes":
        //   this.proposalByStatus(
        //     selected.currentStateIndex,
        //     2,
        //     selected.currentState
        //   );
        //   break;
        default:
          return;
      }
      return this.expandedRowGroups.push(selected.currentState);
    },
    async getToMarkContactManager(streakIndex) {
      this.loading = true;
      let toMarkContactManager = await surveysService.getToMarkContactManager();
      toMarkContactManager = toMarkContactManager.map((v) => ({
        ...v,
        streakIndex: streakIndex,
        date_date: new Date(`${v.date} 00:00`),
      }));
      this.surveysList = this.surveysList.concat(toMarkContactManager);
      return (this.loading = false);
    },
    async getAttemptSchedule(streakIndex) {
      this.loading = true;
      let attemptSchedule = await surveysService.getAttemptSchedule();
      attemptSchedule = attemptSchedule.map((v) => ({
        ...v,
        streakIndex: streakIndex,
        date_date: new Date(`${v.date} 00:00`),
      }));
      this.surveysList = this.surveysList.concat(attemptSchedule);
      return (this.loading = false);
    },
    async getToMark(streakIndex) {
      this.loading = true;
      let toMark = await surveysService.getToMark();
      toMark = toMark.map((v) => ({
        ...v,
        streakIndex: streakIndex,
        date_date: new Date(`${v.date} 00:00`),
      }));
      this.surveysList = this.surveysList.concat(toMark);
      return (this.loading = false);
    },
    async getScheduled(streakIndex) {
      this.loading = true;
      let scheduled = await surveysService.getScheduled();
      scheduled = scheduled.map((v) => ({
        ...v,
        streakIndex: streakIndex,
        date_date: new Date(`${v.date} 00:00`),
      }));
      this.surveysList = this.surveysList.concat(scheduled);
      return (this.loading = false);
    },
    async getPending(streakIndex) {
      this.loading = true;
      let pending = await surveysService.getPending();
      pending = pending.map((v) => ({
        ...v,
        streakIndex: streakIndex,
        date_date: new Date(`${v.date} 00:00`),
      }));
      this.surveysList = this.surveysList.concat(pending);
      if (
        this.can("seeProposalsInSurveysList") ||
        this.$store.state.auth.user.isEsa
      ) {
        return this.proposalByStatus(streakIndex, 2, "Pendente");
      }
      return (this.loading = false);
    },
    async getUnfinished(streakIndex) {
      this.loading = true;
      let unfinished = await surveysService.getUnfinished();
      unfinished = unfinished.map((v) => ({
        ...v,
        streakIndex: streakIndex,
        date_date: new Date(`${v.date} 00:00`),
      }));
      this.surveysList = this.surveysList.concat(unfinished);
      return (this.loading = false);
    },
    async getWithoutReport(streakIndex) {
      this.loading = true;
      let withoutReport = await surveysService.getWithoutReport();
      withoutReport = withoutReport.map((v) => ({
        ...v,
        streakIndex: streakIndex,
        date_date: new Date(`${v.date} 00:00`),
      }));
      this.surveysList = this.surveysList.concat(withoutReport);
      return (this.loading = false);
    },
    async getUnclosed(streakIndex) {
      this.loading = true;
      let unclosed = await surveysService.getUnclosed();
      unclosed = unclosed.map((v) => ({
        ...v,
        streakIndex: streakIndex,
        date_date: new Date(`${v.date} 00:00`),
      }));
      this.surveysList = this.surveysList.concat(unclosed);
      return (this.loading = false);
    },
    async getWithoutBudget(streakIndex) {
      this.loading = true;
      let withoutBudget = await surveysService.getWithoutBudget();
      withoutBudget = withoutBudget.map((v) => ({
        ...v,
        to_do: "Sem Orçamento c/ Survey",
        streakIndex: streakIndex,
        date_date: new Date(`${v.date} 00:00`),
      }));
      this.surveysList = this.surveysList.concat(withoutBudget);
      return (this.loading = false);
    },
    async proposalUnbudgeted(streakIndex) {
      this.loading = true;
      let withoutBudget = await proposalService.getUnbudgeted();
      withoutBudget = withoutBudget.map((v) => ({
        ...v,
        itemType: "proposal",
        to_do: "Sem Orçamento s/ Surveys",
        streakIndex: streakIndex,
        date_date: new Date(`${v.created_at} 00:00`),
        name: `${v.client ? v.client.name : ""} | ${v.subject}`,
        resume: v.description,
        seller: v.seller ? v.seller.name : null,
        technician: v.proposal_assigned_user
          ? v.proposal_assigned_user.username
          : null,
        office: v.proposal_assigned_user
          ? v.proposal_assigned_user.office
          : null,
        hasComments: false,
        userToDo: v.proposal_assigned_user
          ? v.proposal_assigned_user.username
          : null,
      }));
      this.surveysList = this.surveysList.concat(withoutBudget);
      return (this.loading = false);
    },
    async proposalWithoutClassification(streakIndex) {
      this.loading = true;
      let withoutClassification =
        await proposalService.getWithoutClassification();
      withoutClassification = withoutClassification.map((v) => ({
        ...v,
        itemType: "proposal",
        to_do: "Por Classificar",
        streakIndex: streakIndex,
        date_date: new Date(`${v.created_at} 00:00`),
        name: `${v.client ? v.client.name : ""} | ${v.subject}`,
        resume: v.description,
        seller: v.seller ? v.seller.name : null,
        technician: v.proposal_assigned_user
          ? v.proposal_assigned_user.username
          : null,
        office: v.proposal_assigned_user
          ? v.proposal_assigned_user.office
          : null,
        hasComments: false,
        userToDo: v.proposal_assigned_user
          ? v.proposal_assigned_user.username
          : null,
      }));
      this.surveysList = this.surveysList.concat(withoutClassification);
      return (this.loading = false);
    },
    async proposalOpenAndClassified(streakIndex) {
      this.loading = true;
      let withoutClassification = await proposalService.getOpenAndClassified();
      withoutClassification = withoutClassification.map((v) => ({
        ...v,
        itemType: "proposal",
        to_do: "Sem Pat",
        streakIndex: streakIndex,
        date_date: new Date(`${v.created_at} 00:00`),
        name: `${v.client ? v.client.name : ""} | ${v.subject}`,
        resume: v.description,
        seller: v.seller ? v.seller.name : null,
        technician: v.proposal_assigned_user
          ? v.proposal_assigned_user.username
          : null,
        office: v.proposal_assigned_user
          ? v.proposal_assigned_user.office
          : null,
        hasComments: false,
        userToDo: v.proposal_assigned_user
          ? v.proposal_assigned_user.username
          : null,
      }));
      this.surveysList = this.surveysList.concat(withoutClassification);
      return (this.loading = false);
    },
    async proposalByStatus(streakIndex, status, toDo) {
      this.loading = true;
      let byStatus = await proposalService.getByStatus(status);
      byStatus = byStatus.map((v) => ({
        ...v,
        itemType: "proposal",
        to_do: toDo,
        streakIndex: streakIndex,
        date_date: new Date(`${v.created_at} 00:00`),
        name: `${v.client ? v.client.name : ""} | ${v.subject}`,
        resume: v.description,
        seller: v.seller ? v.seller.name : null,
        technician: v.proposal_assigned_user
          ? v.proposal_assigned_user.username
          : null,
        office: v.proposal_assigned_user
          ? v.proposal_assigned_user.office
          : null,
        hasComments: false,
        userToDo: v.proposal_assigned_user
          ? v.proposal_assigned_user.username
          : null,
      }));
      this.surveysList = this.surveysList.concat(byStatus);
      return (this.loading = false);
    },
    viewPatInfo(pat) {
      let route = this.$router.resolve({
        path: `/pat-details/${pat}`,
      });
      window.open(route.href, "_blank");
    },
    viewProposal(id) {
      let route = this.$router.resolve({
        path: `/proposal-detail/${id}`,
      });
      window.open(route.href, "_blank");
    },
    changeSurveySeller(pat) {
      this.changeSeller.show = true;
      this.changeSeller.id = pat.id;
      this.changeSeller.sellerId = parseInt(pat.sellerId);
      this.changeSeller.pat = pat;
    },
    changeSurveySellerCancel() {
      this.changeSeller = {
        show: false,
        id: null,
        sellerId: null,
      };
    },
    changedSurveySeller(response) {
      if (response.status == "ok") {
        this.changeSeller.pat.sellerId = response.currentSeller.id;
        this.changeSeller.pat.seller = response.currentSeller.name;
      }
      return this.changeSurveySellerCancel();
    },
    changeSurveyDate(pat) {
      this.changeDate.show = true;
      this.changeDate.patId = pat.id;
      this.changeDate.patName = pat.name;
      this.changeDate.patResume = pat.resume;
      this.changeDate.patDate = pat.date;
      this.changeDate.pat = pat;
    },
    surveyChangeDateCancel() {
      this.changeDate = {
        show: false,
        patId: null,
        patName: null,
        patResume: null,
        patDate: null,
      };
    },
    surveyChangeDateSubmit(response) {
      if (response.status == "ok") {
        this.changeDate.pat.date = response.currentDate;
        this.streakStatusChange(this.changeDate.pat.to_do, "Marcado");
        this.changeDate.pat.to_do = "Marcado";
      }
      return this.surveyChangeDateCancel();
    },
    changeSurveyResposible(pat) {
      this.changeResponsible.show = true;
      this.changeResponsible.id = pat.id;
      this.changeResponsible.technicianUserId = pat.technicianUserId;
      this.changeResponsible.pat = pat;
    },
    changeSurveyResponsibleCancel() {
      this.changeResponsible = {
        show: false,
        id: null,
        technicianUserId: null,
      };
    },
    changedSurveyResponsible(response) {
      if (response.status == "ok") {
        this.changeResponsible.pat.technicianUserId =
          response.currentResponsible.id;
        this.changeResponsible.pat.technician =
          response.currentResponsible.name;
        this.changeResponsible.pat.office = response.currentResponsible.office;
      }
      return this.changeSurveyResponsibleCancel();
    },
    showStatusMenu(survey) {
      this.statusMenu.show = true;
      this.statusMenu.survey = survey;
    },
    closeStatusMenu() {
      this.statusMenu.show = false;
      this.statusMenu.survey = null;
    },
    setPending(survey) {
      this.statusMenu.show = false;
      this.setStatusPending.show = true;
      this.setStatusPending.patId = survey.id;
      this.setStatusPending.patName = `${survey.name} | ${survey.resume}`;
      this.setStatusPending.survey = survey;
    },
    setSurveyPendingCancel() {
      this.setStatusPending = {
        show: false,
        patId: 0,
        patName: null,
      };
    },
    setSurveyPending(response) {
      if (response.status == "ok") {
        this.streakStatusChange(
          this.setStatusPending.survey.to_do,
          response.message.surveyStatus
        );
        this.setStatusPending.survey.status = response.message.patStatus;
        this.setStatusPending.survey.lastComment =
          response.message.surveyLastComment;
        this.setStatusPending.survey.to_do = response.message.surveyStatus;
        this.setStatusPending.survey.streakIndex = this.getStreakStatusIndex(
          this.setStatusPending.survey.to_do
        );
      }
      return this.setSurveyPendingCancel();
    },
    removePending(survey) {
      this.removeStatusPending.show = true;
      this.removeStatusPending.patId = survey.id;
      this.removeStatusPending.patName = `${survey.name} | ${survey.resume}`;
      this.removeStatusPending.survey = survey;
    },
    removePendingStatusCancel() {
      this.removeStatusPending = {
        show: false,
        patId: 0,
        patName: null,
      };
    },
    removePendingStatus(response) {
      if (response.status == "ok") {
        this.streakStatusChange(
          this.removeStatusPending.survey.to_do,
          response.currentStatus.surveyStatus
        );
        this.removeStatusPending.survey.status =
          response.currentStatus.patStatus;
        this.removeStatusPending.survey.lastComment =
          response.currentStatus.surveyLastComment;
        this.removeStatusPending.survey.to_do =
          response.currentStatus.surveyStatus;
        this.removeStatusPending.survey.streakIndex = this.getStreakStatusIndex(
          this.removeStatusPending.survey.to_do
        );
      }
      return this.setSurveyPendingCancel();
    },
    setRealized(survey) {
      this.statusMenu.show = false;
      this.setStatusRealized.show = true;
      this.setStatusRealized.patId = survey.id;
      this.setStatusRealized.patName = `${survey.name} | ${survey.resume}`;
      this.setStatusRealized.survey = survey;
    },
    setRealizedCancel() {
      return (this.setStatusRealized = {
        show: false,
        patId: null,
        patType: "Survey",
        patName: null,
      });
    },
    setRealizedOk(response) {
      if (response.status == "ok") {
        this.streakStatusChange(
          this.setStatusRealized.survey.to_do,
          response.currentStatus.surveyStatus
        );
        this.setStatusRealized.survey.status = response.currentStatus.patStatus;
        this.setStatusRealized.survey.to_do =
          response.currentStatus.surveyStatus;
        this.setStatusRealized.survey.streakIndex = this.getStreakStatusIndex(
          this.setStatusRealized.survey.to_do
        );
      }
      return this.setRealizedCancel();
    },
    insertReport(survey) {
      this.statusMenu.show = false;
      this.addSurveyReport.patId = survey.id;
      this.addSurveyReport.patReference = survey.reference;
      this.addSurveyReport.name = `${survey.name} | ${survey.resume}`;
      this.addSurveyReport.show = true;
      this.addSurveyReport.survey = survey;
    },
    addReportCancel() {
      return (this.addSurveyReport = {
        show: false,
        patId: null,
        patName: null,
        patReference: null,
      });
    },
    addedReport(response) {
      if (response.status == "ok") {
        this.streakStatusChange(
          this.addSurveyReport.survey.to_do,
          response.currentStatus.surveyStatus
        );
        this.addSurveyReport.survey.to_do = response.currentStatus.surveyStatus;
        this.addSurveyReport.survey.streakIndex = this.getStreakStatusIndex(
          this.addSurveyReport.survey.to_do
        );

        if (
          !this.$store.state.auth.user.isAdmin &&
          !this.$store.state.auth.user.isEsa
        ) {
          this.removeByStatus(response.currentStatus.surveyStatus);
        }
      }
      return this.addReportCancel();
    },
    concluedSurvey(survey) {
      this.setSurveyClosed.patId = survey.id;
      this.setSurveyClosed.patName = `${survey.name} | ${survey.resume}`;
      this.setSurveyClosed.show = true;
      this.setSurveyClosed.survey = survey;
    },
    concluedSurveyCancel() {
      return (this.setSurveyClosed = {
        show: false,
        patId: null,
        patName: null,
        patType: "Survey",
      });
    },
    concluedSurveyOk(response) {
      if (response.status == "ok") {
        if (!this.setSurveyClosed.survey.budgeted) {
          response.currentStatus.surveyStatus = "Sem Orçamento";
        } else {
          response.currentStatus.surveyStatus = "";
        }
        this.streakStatusChange(
          this.setSurveyClosed.survey.to_do,
          response.currentStatus.surveyStatus
        );
        this.setSurveyClosed.survey.status = response.currentStatus.patStatus;
        this.setSurveyClosed.survey.to_do = response.currentStatus.surveyStatus;
        this.setSurveyClosed.survey.streakIndex = this.getStreakStatusIndex(
          this.setSurveyClosed.survey.to_do
        );

        if (this.setSurveyClosed.survey.budgeted) {
          this.removeByStatus(response.currentStatus.surveyStatus);
        }
      }
      return this.concluedSurveyCancel();
    },
    setBudget(survey) {
      this.closeStatusMenu();
      this.setSurveyBudget.patId = survey.id;
      this.setSurveyBudget.budgeted = survey.budgeted;
      this.setSurveyBudget.patName = `${survey.name} | ${survey.resume}`;
      this.setSurveyBudget.show = true;
      this.setSurveyBudget.survey = survey;
    },
    setBudgetedCancel() {
      return (this.setSurveyBudget = {
        show: false,
        patId: null,
        budgeted: null,
        patType: "Survey",
        patName: null,
      });
    },
    setBudgetedOk(response) {
      if (response.status == "ok") {
        this.streakStatusChange(this.setSurveyBudget.survey.to_do, "");
        this.setSurveyBudget.survey.to_do = "";

        this.removeByStatus("");
      }
      return this.setBudgetedCancel();
    },
    changeSurveyRespBudgey(survey) {
      this.changeBudgetedResponsible.patId = survey.id;
      this.changeBudgetedResponsible.currentRespId = survey.userToDoId;
      this.changeBudgetedResponsible.survey = survey;
      this.changeBudgetedResponsible.show = true;
    },
    changeBudgetResponsibleCancel() {
      return (this.changeBudgetedResponsible = {
        show: false,
        patId: null,
        currentRespId: null,
      });
    },
    changedBudgetResponsible(response) {
      if (response.status == "ok") {
        this.changeBudgetedResponsible.survey.userToDoId =
          response.currentResponsible.id;
        this.changeBudgetedResponsible.survey.userToDo =
          response.currentResponsible.name;
      }
      return this.changeBudgetResponsibleCancel();
    },
    setMarked(survey) {
      this.closeStatusMenu();
      return (this.setSurveyMarked = {
        show: true,
        patId: survey.id,
        patName: survey.name,
        patResume: survey.resume,
        pat: survey,
      });
    },
    setMarkedCancel() {
      this.closeStatusMenu();
      return (this.setSurveyMarked = {
        show: false,
        patId: null,
        patName: null,
        patResume: null,
      });
    },
    setMarkedOk(response) {
      if (response.status == "ok") {
        this.setSurveyMarked.pat.technicianUserId = response.respTechnicalId;
        this.setSurveyMarked.pat.technician = response.respTechnical;
        this.setSurveyMarked.pat.to_do = response.currenStatus;
        this.setSurveyMarked.pat.streakIndex = this.getStreakStatusIndex(
          this.setSurveyMarked.pat.to_do
        );
        this.setSurveyMarked.pat.date = response.surveyDate;
      }
      return this.setMarkedCancel();
    },
    numberAttemptSchedule(info) {
      return `${info.to_do} - ${info.attemptSchedule.split("_")[0]}`;
    },
    addAttemptSchedule(survey) {
      this.closeStatusMenu();
      this.addScheduleAttempt.patId = survey.id;
      this.addScheduleAttempt.patName = survey.name;
      this.addScheduleAttempt.survey = survey;
      this.addScheduleAttempt.show = true;
    },
    addAttemptScheduleCancel() {
      return (this.addScheduleAttempt = {
        show: false,
        patId: null,
        patName: null,
      });
    },
    addAttemptScheduleOk(response) {
      if (response.status == "ok") {
        this.addScheduleAttempt.survey.hasComments = true;
        this.streakStatusChange(
          this.addScheduleAttempt.survey.to_do,
          response.currenStatus
        );
        this.addScheduleAttempt.survey.to_do = response.currenStatus;
        this.addScheduleAttempt.survey.attemptSchedule =
          response.attemptSchedule;
        this.addScheduleAttempt.survey.streakIndex = this.getStreakStatusIndex(
          this.addScheduleAttempt.survey.to_do
        );
      }
      return this.addAttemptScheduleCancel();
    },
    cancelViewComments() {
      return (this.comments = { show: false, survey: null, comments: [] });
    },
    viewComments(survey) {
      this.loading = true;
      this.comments.survey = survey;
      return surveysService.getComments(survey.id).then((response) => {
        this.comments.comments = response;
        this.comments.show = true;
        return (this.loading = false);
      });
    },
    setCanceled(survey) {
      this.closeStatusMenu();
      this.setSurveyCanceled.patId = survey.id;
      this.setSurveyCanceled.patName = `${survey.name} | ${survey.resume}`;
      this.setSurveyCanceled.show = true;
      this.setSurveyCanceled.survey = survey;
    },
    setCanceledCancel() {
      return (this.setSurveyCanceled = {
        show: false,
        patId: null,
        patName: null,
      });
    },
    setCanceledOk(response) {
      if (response.status == "ok") {
        this.streakStatusChange(this.setSurveyCanceled.survey.to_do, "");
        this.setSurveyCanceled.survey.to_do = "";

        this.removeByStatus("");
      }
      return this.setCanceledCancel();
    },
    proChangeResposible(proposal) {
      return (this.proposalChangeResponsible = {
        show: true,
        proposalId: proposal.id,
        proposalSubject: proposal.subject,
        technicianId: proposal.assigned_to,
        proposal: proposal,
      });
    },
    proChangeResposibleCancel() {
      return (this.proposalChangeResponsible = {
        show: false,
        proposalId: null,
        proposalSubject: null,
        technicianId: null,
      });
    },
    proChangeResposibleOk(response) {
      if (response.status == "ok") {
        this.proposalChangeResponsible.proposal.assigned_to =
          response.result.technician_id;
        this.proposalChangeResponsible.proposal.technician =
          response.result.responsible_user;
        this.proposalChangeResponsible.proposal.office = response.result.office;
        this.proposalChangeResponsible.proposal.proposal_assigned_user = {
          userno: response.result.responsible_user_id,
          username: response.result.responsible_user,
          tecnico: response.result.technician_id,
        };
      }
      return this.proChangeResposibleCancel();
    },
    setPropBudget(proposal) {
      this.closeStatusMenu();
      this.setProposalBudget.proposalId = proposal.id;
      this.setProposalBudget.budgeted = proposal.budgeted;
      this.setProposalBudget.proposalName = proposal.name;
      this.setProposalBudget.show = true;
      this.setProposalBudget.proposal = proposal;
    },
    setProposalBudgetedCancel() {
      return (this.setProposalBudget = {
        show: false,
        proposalId: null,
        budgeted: null,
        proposalName: null,
      });
    },
    setProposalBudgetedOk(response) {
      if (response.status == "ok") {
        this.streakStatusChange(this.setProposalBudget.proposal.to_do, "");
        this.setProposalBudget.proposal.to_do = "";

        this.removeByStatus("");
      }
      return this.setProposalBudgetedCancel();
    },
  },
};
</script>
<style>
.p-column-filter {
  width: 100%;
}

.p-tooltip {
  z-index: 5;
}
.p-datatable .p-datatable-loading-overlay {
  z-index: 5;
}

.myGroupHeader {
  display: inline-flex;
  height: 25px;
  padding: 0 15px;
  align-items: center;
  font-weight: bold;
  border-radius: 25px;
  margin-left: 15px;
  cursor: default;
}

.p-datatable-scrollable .p-datatable-thead {
  z-index: 2 !important;
}

.clickable-item {
  cursor: pointer;
  color: #000;
  text-decoration-line: underline;
  text-decoration-style: dashed;
}

.streak-icon {
  transform: rotate(90deg);
}

.p-dialog .p-dialog-header-icons {
  display: none !important;
}
.p-column-header-content {
  text-align: center !important;
}
.align-center .p-column-header-content {
  justify-content: center !important;
}
th.align-center {
  justify-content: center !important;
}
</style>
