var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(
    (_vm.installationsSatisfactionForm > 0 && _vm.can('seeInstallationSurveys')) ||
    (_vm.installationsSatisfactionForm > 0 && _vm.can('seeInstallationSurveys')) ||
    (_vm.supportSatisfactionForm > 0 && _vm.can('seeAssistSurveys')) ||
    (_vm.unavailabilityToApprove > 0 && _vm.can('approvesUnavailability')) ||
    (_vm.numberOfBookings > 0 && _vm.can('seeBookinglistMenu')) ||
    _vm.assistsCompletedByClose > 0
  ),expression:"\n    (installationsSatisfactionForm > 0 && can('seeInstallationSurveys')) ||\n    (installationsSatisfactionForm > 0 && can('seeInstallationSurveys')) ||\n    (supportSatisfactionForm > 0 && can('seeAssistSurveys')) ||\n    (unavailabilityToApprove > 0 && can('approvesUnavailability')) ||\n    (numberOfBookings > 0 && can('seeBookinglistMenu')) ||\n    assistsCompletedByClose > 0\n  "}]},[_c('W4MstreakIndicators',{attrs:{"indicators":_vm.streakIndicators,"loader":false},on:{"W4MstreakIndicatorsClick":_vm.streakIndicatorsClick}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }