<template>
  <div class="card p-shadow-6">
    <h2>Indisponibilidades</h2>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog></ConfirmDialog>

    <div style="height: 65vh">
      <DataTable
        :value="unavailabilityList"
        :filters.sync="filters"
        filterDisplay="row"
        :loading="loading"
        class="p-datatable-sm"
        :sortOrder="1"
        responsiveLayout="scroll"
        removableSort
        sortMode="single"
        :scrollable="true"
        scrollHeight="flex"
        :autoLayout="true"
      >
        <template #empty> Não existem indisponibilidades. </template>
        <template #loading> A carregar. Por favor Aguarde... </template>
        <Column
          field="date"
          filterField="date"
          header="Dia"
          sortable
          filterMatchMode="contains"
          :headerStyle="{ 'min-width': '10%', 'max-width': '10%' }"
          :filterHeaderStyle="{ 'min-width': '10%', 'max-width': '10%' }"
          :bodyStyle="{ 'min-width': '10%', 'max-width': '10%' }"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="start"
          filterField="start"
          header="Inicio"
          :headerStyle="{ 'min-width': '10%', 'max-width': '10%' }"
          :filterHeaderStyle="{ 'min-width': '10%', 'max-width': '10%' }"
          :bodyStyle="{ 'min-width': '10%', 'max-width': '10%' }"
        />
        <Column
          field="end"
          filterField="end"
          header="Fim"
          :headerStyle="{ 'min-width': '10%', 'max-width': '10%' }"
          :filterHeaderStyle="{ 'min-width': '10%', 'max-width': '10%' }"
          :bodyStyle="{ 'min-width': '10%', 'max-width': '10%' }"
        />
        <Column
          field="resume"
          filterField="resume"
          header="Razão Indisponibilidade"
        >
          <template #body="slotProps">
            {{ slotProps.data.resume }}
            {{ slotProps.data.description }}
            {{ slotProps.data.note }}
          </template>
        </Column>
        <Column
          field="approved"
          header="Aprovada"
          filterField="approved"
          dataType="boolean"
          sortable
          :headerStyle="{ 'min-width': '10%', 'max-width': '10%' }"
          :filterHeaderStyle="{ 'min-width': '10%', 'max-width': '10%' }"
          :bodyStyle="{ 'min-width': '10%', 'max-width': '10%' }"
        >
          <template #body="slotProps">
            {{ slotProps.data.approved == 1 ? "Sim" : "Não" }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              v-model="filterModel.value"
              @input="filterCallback()"
              :options="[
                { value: 0, label: 'Não' },
                { value: 1, label: 'Sim' },
              ]"
              optionValue="value"
              optionLabel="label"
              class="p-column-filter"
              :showClear="true"
            >
            </Dropdown>
          </template>
        </Column>
        <Column
          field="reason_reject"
          filterField="reason_reject"
          header="Razão de Rejeição"
        />
      </DataTable>
    </div>
  </div>
</template>
<script>
import { FilterMatchMode } from "primevue/api/";
import bookingService from "../services/booking.service";
export default {
  name: "UnavailabilityToApprove",
  components: {},
  data() {
    return {
      loading: true,
      unavailabilityList: [],
      filters: {
        date: { value: null, matchMode: FilterMatchMode.CONTAINS },
        approved: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
    };
  },
  async created() {
    this.unavailabilityList = await bookingService.getUnavailability();
    this.loading = false;
  },
  methods: {},
};
</script>
