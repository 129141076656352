import Vue from "vue";
import App from "./App.vue";
import { router } from "./router";
import store from "./store";
import VeeValidate from "vee-validate";
import Vuex from "vuex";
import config from "./config";
import acls from "./mixins/acls.js";

/**
 * Theme Components
 */
import PrimeVue from "primevue/config";
import "primevue/resources/themes/bootstrap4-light-blue/theme.css"; //theme
import "primeflex/primeflex.css"; //primeFlex utilities
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "@/assets/layout/layout.scss";
import "./assets/layout/flags/flags.css";
import "leaflet/dist/leaflet.css";

import Ripple from "primevue/ripple";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import ToastService from "primevue/toastservice";
import Toast from "primevue/toast";
import Tooltip from "@ruilopes/primevue/tooltip";
import FullCalendar from "@fullcalendar/vue";
import ConfirmationService from "primevue/confirmationservice";
import ConfirmDialog from "primevue/confirmdialog";
import Dialog from "primevue/dialog";
import Calendar from "primevue/calendar";
import Divider from "primevue/divider";
import Textarea from "primevue/textarea";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputNumber from "primevue/inputnumber";
import Toolbar from "primevue/toolbar";
import InputMask from "primevue/inputmask";
import InputSwitch from "primevue/inputswitch";
import FileUpload from "primevue/fileupload";
import Sidebar from "primevue/sidebar";
import Tree from "primevue/tree";
import Checkbox from "primevue/checkbox";
import SelectButton from "primevue/selectbutton";
import Card from "primevue/card";
import RadioButton from "primevue/radiobutton";
import Message from "primevue/message";
import ImagePreview from "primevue/imagepreview";
import Rating from "primevue/rating";
import Fieldset from "primevue/fieldset";
import Badge from "primevue/badge";
import BadgeDirective from "primevue/badgedirective";
import Dropdown from "primevue/dropdown";
import MultiSelect from "primevue/multiselect";
import Editor from "primevue/editor";
import OverlayPanel from "primevue/overlaypanel";
import AccordionTab from "primevue/accordiontab";
import Accordion from "primevue/accordion";
import Chips from "primevue/chips";
import Password from "primevue/password";
import AutoComplete from "primevue/autocomplete";
import Paginator from 'primevue/paginator';
import VCalendar from "v-calendar";
Vue.use(VCalendar);
import VueMobileDetection from "vue-mobile-detection";
Vue.use(VueMobileDetection);

import { Timeline } from "vue-visjs";
Vue.component("timeline", Timeline);

// import '@fullcalendar/core/main.min.css';
// import "@fullcalendar/daygrid/main.min.css";
// import "@fullcalendar/timegrid/main.min.css";
import "@/assets/global.css";

Vue.use(ConfirmationService);
Vue.use(ToastService);
Vue.use(PrimeVue, {
  locale: {
    startsWith: "Começa com",
    contains: "Contém",
    notContains: "Não contém",
    endsWith: "Termina com",
    equals: "É igual a",
    notEquals: "Não é igual",
    noFilter: "Limpar Filtro",
    lt: "Menor que",
    lte: "Menor ou igual a",
    gt: "Maior que",
    gte: "Maior ou igual a ",
    dateIs: "Data é",
    dateIsNot: "Date não é",
    dateBefore: "Data anterior a",
    dateAfter: "Data depois de",
    clear: "Limpar",
    apply: "Aplicar",
    matchAll: "Corresponder a todos",
    matchAny: "Corresponder a qualquer",
    addRule: "Adicionar regra",
    removeRule: "Remover regra",
    accept: "Sim",
    reject: "Não",
    monthNamesShort: [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez",
    ],
  },
});
Vue.directive("tooltip", Tooltip);
Vue.directive("ripple", Ripple);
Vue.directive("badge", BadgeDirective);
// Vue.prototype.$primevue = Vue.observable({ripple: true});
Vue.prototype.appConfig = config;
Vue.prototype.$appState = Vue.observable({ inputStyle: "outlined" });
Vue.prototype.$loggedUser = JSON.parse(localStorage.getItem("user"));
if (Vue.prototype.$loggedUser != undefined) {
  delete Vue.prototype.$loggedUser["token"];
}
//End Template Components

Vue.config.productionTip = false;
Vue.use(VeeValidate);
Vue.use(Vuex);

/**
 * Template Components
 */
Vue.component("Button", Button);
Vue.component("InputText", InputText);
Vue.component("Toast", Toast);
Vue.component("FullCalendar", FullCalendar);
Vue.component("ConfirmDialog", ConfirmDialog);
Vue.component("Dialog", Dialog);
Vue.component("Calendar", Calendar);
Vue.component("Dropdown", Dropdown);
Vue.component("Divider", Divider);
Vue.component("Textarea", Textarea);
Vue.component("DataTable", DataTable);
Vue.component("Column", Column);
Vue.component("InputNumber", InputNumber);
Vue.component("Toolbar", Toolbar);
Vue.component("InputMask", InputMask);
Vue.component("InputSwitch", InputSwitch);
Vue.component("FileUpload", FileUpload);
Vue.component("Sidebar", Sidebar);
Vue.component("Tree", Tree);
Vue.component("Checkbox", Checkbox);
Vue.component("MultiSelect", MultiSelect);
Vue.component("SelectButton", SelectButton);
Vue.component("Card", Card);
Vue.component("RadioButton", RadioButton);
Vue.component("Message", Message);
Vue.component("ImagePreview", ImagePreview);
Vue.component("Rating", Rating);
Vue.component("Fieldset", Fieldset);
Vue.component("Badge", Badge);
Vue.component("Editor", Editor);
Vue.component("OverlayPanel", OverlayPanel);
Vue.component("Accordion", Accordion);
Vue.component("AccordionTab", AccordionTab);
Vue.component("Chips", Chips);
Vue.component("Password", Password);
Vue.component("AutoComplete", AutoComplete);
Vue.component("Paginator", Paginator);
//End Template Components
Vue.mixin(acls);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

