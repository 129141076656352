<template>
  <div
    v-show="
      (installationsSatisfactionForm > 0 && can('seeInstallationSurveys')) ||
      (installationsSatisfactionForm > 0 && can('seeInstallationSurveys')) ||
      (supportSatisfactionForm > 0 && can('seeAssistSurveys')) ||
      (unavailabilityToApprove > 0 && can('approvesUnavailability')) ||
      (numberOfBookings > 0 && can('seeBookinglistMenu')) ||
      assistsCompletedByClose > 0
    "
  >
    <W4MstreakIndicators
      :indicators="streakIndicators"
      :loader="false"
      v-on:W4MstreakIndicatorsClick="streakIndicatorsClick"
    />
  </div>
</template>
<script>
import W4MstreakIndicators from "../W4Mstreak/W4MstreakIndicators.vue";
import bookingService from "../../services/booking.service";
import satisfactionFormService from "../../services/satisfactionForm.service";
import supportService from "../../services/support.service";
export default {
  name: "AlertsSupportIndicators",
  components: {
    W4MstreakIndicators,
  },
  data() {
    return {
      numberOfBookings: 0,
      installationsSatisfactionForm: 0,
      supportSatisfactionForm: 0,
      assistsCompletedByClose: 0,
      unavailabilityToApprove: 0,
      streakIndicators: [],
    };
  },
  async created() {
    if (this.can("seeBookinglistMenu")) {
      this.numberOfBookings =
        await bookingService.countSupportBookingWithoutIntervention(5);
    }
    if (this.can("seeInstallationSurveys")) {
      this.installationsSatisfactionForm =
        await satisfactionFormService.countInstallationsForResponding(
          new Date().getFullYear()
        );
    }
    if (this.can("seeAssistSurveys")) {
      this.supportSatisfactionForm =
        await satisfactionFormService.countSupportForResponding(
          new Date().getFullYear()
        );
    }
    if (this.can("approvesUnavailability")) {
      this.unavailabilityToApprove =
        await bookingService.countUnavailabilityToApprove();
    }
    this.assistsCompletedByClose = await supportService
      .countCompletedByClose()
      .then((response) => {
        return response.user;
      });
    this.initStreakIndicators();
  },
  methods: {
    initStreakIndicators() {
      if (this.unavailabilityToApprove > 0) {
        this.streakIndicators.push({
          title: "Indisponibilidades Por Aprovar",
          counter: this.unavailabilityToApprove,
          tooltip: "Indisponibilidades dos técnicos por aprovar",
        });
      }
      if (this.numberOfBookings > 0) {
        this.streakIndicators.push({
          title: "Reservas Sem Interveção",
          counter: this.numberOfBookings,
          tooltip:
            "Reservas sem Interveção em que a data passou à mais de 5 dias",
        });
      }
      if (this.installationsSatisfactionForm > 0) {
        this.streakIndicators.push({
          title: "Inquéritos Instalações",
          counter: this.numberOfBookings,
          tooltip: "Inquéritos de Satisfação das Instalações por responder",
        });
      }
      if (this.supportSatisfactionForm > 0) {
        this.streakIndicators.push({
          title: "Inquéritos Assistências",
          counter: this.supportSatisfactionForm,
          tooltip: "Inquéritos de Satisfação das Assistências por responder",
        });
      }
      if (this.assistsCompletedByClose > 0) {
        this.streakIndicators.push({
          title: "Assistências Concluidas Por Fechar",
          counter: this.assistsCompletedByClose,
          tooltip: "Assistências Concluidas Por Fechar",
        });
      }
    },
    goBookings() {
      let route = this.$router.resolve({
        path: `/support/bookings`,
      });
      window.open(route.href, "_blank");
    },
    goInstallationSatisfactionForm() {
      let route = this.$router.resolve({
        path: `/satisfaction-form-installations`,
      });
      window.open(route.href, "_blank");
    },
    goSupportSatisfactionForm() {
      let route = this.$router.resolve({
        path: `/satisfaction-form-support`,
      });
      window.open(route.href, "_blank");
    },
    goAssistsCompletedByClose() {
      let route = this.$router.resolve({
        path: `/assists/Concluido Por Fechar`,
      });
      window.open(route.href, "_blank");
    },
    goUnavailabilityToApprove() {
      let route = this.$router.resolve({
        path: `/booking/unavailability/toApprove`,
      });
      window.open(route.href, "_blank");
    },
    streakIndicatorsClick(indicator) {
      if (indicator.indicatorName === "Reservas Sem Interveção") {
        return this.goBookings();
      } else if (indicator.indicatorName === "Inquéritos Instalações") {
        return this.goInstallationSatisfactionForm();
      } else if (indicator.indicatorName === "Inquéritos Assistências") {
        return this.goSupportSatisfactionForm();
      } else if (
        indicator.indicatorName === "Assistências Concluidas Por Fechar"
      ) {
        return this.goAssistsCompletedByClose();
      } else if (indicator.indicatorName === "Indisponibilidades Por Aprovar") {
        return this.goUnavailabilityToApprove();
      }
      return;
    },
  },
};
</script>
