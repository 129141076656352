<template>
  <div class="card p-shadow-6">
    <h2>Indisponibilidades por Aprovar</h2>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog></ConfirmDialog>

    <div style="height: 65vh">
      <DataTable
        :value="unavailabilityList"
        :filters.sync="filters"
        filterDisplay="row"
        :loading="loading"
        class="p-datatable-sm"
        :sortOrder="1"
        responsiveLayout="scroll"
        removableSort
        sortMode="single"
        :scrollable="true"
        scrollHeight="flex"
        :autoLayout="true"
      >
        <template #empty>
          Não existem indisponibilidades por aprovar.
        </template>
        <template #loading> A carregar. Por favor Aguarde... </template>
        <Column
          field="technician"
          filterField="technician"
          header="Técnico"
          sortable
          filterMatchMode="contains"
          :headerStyle="{ 'min-width': '20%', 'max-width': '20%' }"
          :filterHeaderStyle="{ 'min-width': '20%', 'max-width': '20%' }"
          :bodyStyle="{ 'min-width': '20%', 'max-width': '20%' }"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="date"
          filterField="date"
          header="Dia"
          sortable
          filterMatchMode="contains"
          :headerStyle="{ 'min-width': '10%', 'max-width': '10%' }"
          :filterHeaderStyle="{ 'min-width': '10%', 'max-width': '10%' }"
          :bodyStyle="{ 'min-width': '10%', 'max-width': '10%' }"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="start"
          filterField="start"
          header="Inicio"
          :headerStyle="{ 'min-width': '10%', 'max-width': '10%' }"
          :filterHeaderStyle="{ 'min-width': '10%', 'max-width': '10%' }"
          :bodyStyle="{ 'min-width': '10%', 'max-width': '10%' }"
        />
        <Column
          field="end"
          filterField="end"
          header="Fim"
          :headerStyle="{ 'min-width': '10%', 'max-width': '10%' }"
          :filterHeaderStyle="{ 'min-width': '10%', 'max-width': '10%' }"
          :bodyStyle="{ 'min-width': '10%', 'max-width': '10%' }"
        />
        <Column
          field="resume"
          filterField="resume"
          header="Razão Indisponibilidade"
        >
          <template #body="slotProps">
            {{ slotProps.data.resume }}
            {{ slotProps.data.description }}
            {{ slotProps.data.note }}
          </template>
        </Column>
        <Column
          :headerStyle="{ 'min-width': '6%', 'max-width': '6%' }"
          :filterHeaderStyle="{ 'min-width': '6%', 'max-width': '6%' }"
          :bodyStyle="{ 'min-width': '6%', 'max-width': '6%' }"
        >
          <template #body="slotProps">
            <Button
              icon="pi pi-check"
              v-tooltip.left="'Aprovar'"
              class="p-button-sm p-button-rounded p-button-success p-mr-1"
              @click="accept(slotProps.data)"
            />
            <Button
              icon="pi pi-times"
              v-tooltip.left="'Rejeitar'"
              class="p-button-sm p-button-rounded p-button-danger"
              @click="reject(slotProps.data)"
            />
          </template>
        </Column>
      </DataTable>
    </div>
    <Dialog
      :visible.sync="unavailabilityReject.show"
      :style="{ width: $isMobile() ? '100vw' : '40vw' }"
      :header="'Rejeitar Indisponibilidade'"
      :modal="true"
      :contentStyle="{ overflow: 'visible' }"
    >
      <form name="rejectForm" class="p-col-12" @submit.prevent="confirmReject">
        <div class="p-fluid p-formgrid p-grid">
          <div
            class="p-field p-col-12 p-md-12"
            v-if="unavailabilityReject.unavailability"
          >
            {{ unavailabilityReject.unavailability.technician }} |
            {{ unavailabilityReject.unavailability.date }} |
            {{ unavailabilityReject.unavailability.start }} |
            {{ unavailabilityReject.unavailability.end }} |
            {{ unavailabilityReject.unavailability.resume }}
            {{ unavailabilityReject.unavailability.description }}
            {{ unavailabilityReject.unavailability.note }}
          </div>
          <div class="p-field p-col-12 p-md-12">
            <span class="p-float-label">
              <Textarea
                type="text"
                name="reason_reject"
                id="reason_reject"
                v-validate="'required'"
                :autoResize="true"
                rows="5"
                v-model="unavailabilityReject.reason_reject"
                v-bind:class="[
                  { 'p-invalid': errors.has('reason_reject') },
                  'form-control',
                ]"
              />
              <label for="aclName">Razão da Rejeição</label>
            </span>
            <small
              v-if="errors.has('reason_reject')"
              class="p-error"
              role="alert"
            >
              Razão da Rejeição é obrigatório
            </small>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="confirmReject"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="clearUnavailabilityReject"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import { FilterMatchMode } from "primevue/api/";
import bookingService from "../services/booking.service";
export default {
  name: "UnavailabilityToApprove",
  components: {},
  data() {
    return {
      loading: true,
      unavailabilityList: [],
      filters: {
        technician: { value: null, matchMode: FilterMatchMode.CONTAINS },
        date: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      unavailabilityReject: {
        show: false,
        unavailability: null,
        reason_reject: null,
      },
    };
  },
  async created() {
    this.unavailabilityList = await bookingService.getUnavailabilityToApprove();
    this.loading = false;
  },
  methods: {
    clearUnavailabilityReject() {
      this.$validator.pause();
      this.$validator.reset();
      return (this.unavailabilityReject = {
        show: false,
        unavailability: null,
        reason_reject: null,
      });
    },
    reject(unavailability) {
      this.unavailabilityReject.unavailability = unavailability;
      return (this.unavailabilityReject.show = true);
    },
    confirmReject() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          return;
        }
        this.$validator.pause();
        this.$validator.reset();

        let params = {
          reason_reject: this.unavailabilityReject.reason_reject,
        };

        return bookingService
          .reject(this.unavailabilityReject.unavailability.id, params)
          .then((response) => {
            if (!response) {
              return this.$toast.add({
                severity: "error",
                summary: "Erro",
                detail: "Ocorreu um erro ao rejeitar a insdisponibilidade",
                life: 3000,
              });
            }

            this.$toast.add({
              severity: "success",
              summary: "Indisponibilidade Rejeitada",
              detail: "A Indisponibilidade foi rejeitada com sucesso",
              life: 3000,
            });
            this.unavailabilityList = this.unavailabilityList.filter(
              (v) => v.id !== this.unavailabilityReject.unavailability.id
            );
            return this.clearUnavailabilityReject();
          });
      });
    },
    accept(unavailability) {
      let message = `
        ${unavailability.technician} |
            ${unavailability.date} |
            ${unavailability.start} |
            ${unavailability.end} |
            ${unavailability.resume}
            ${unavailability.description}
            ${unavailability.note}`;
      this.$confirm.require({
        header: "Aprovar Indisponibilidade",
        message: message,
        icon: "pi pi-exclamation-triangle",
        rejectLabel: "Não",
        rejectIcon: "pi pi-times",
        rejectClass: "p-button-danger p-button p-component",
        acceptLabel: "Sim",
        acceptIcon: "pi pi-check",
        acceptClass: "p-button-success p-button p-component",
        accept: () => {
          return bookingService.approve(unavailability.id).then((response) => {
            if (!response) {
              return this.$toast.add({
                severity: "error",
                summary: "Erro",
                detail: "Ocorreu um erro ao aprovar a insdisponibilidade",
                life: 3000,
              });
            }

            this.$toast.add({
              severity: "success",
              summary: "Indisponibilidade Aprovada",
              detail: "A Indisponibilidade foi aprovada com sucesso",
              life: 3000,
            });
            this.unavailabilityList = this.unavailabilityList.filter(
              (v) => v.id !== unavailability.id
            );
            return this.clearUnavailabilityReject();
          });
        },
        reject: () => {},
      });
    },
  },
};
</script>
