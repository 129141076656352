<template>
  <div class="card p-shadow-6">
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />

    <ClassificationForm
      :showToast="true"
      :show="showCreateForm"
      v-on:cancel="showCreateForm = false"
      v-on:savedBusinessClassification="createClassificationOk"
    />

    <AddBusinessToClassification
      :show="showAssociateForm"
      v-on:cancel="showAssociateForm = false"
      v-on:selectedClassification="selectedClassification"
    />

    <h1>Classificação dos Negócios</h1>
    <div class="p-mb-4">
      <W4MstreakIndicators
        :indicators="streakIndicators"
        :loader="indicatorLoading"
        v-on:W4MstreakIndicatorsClick="streakIndicatorsClick"
      />
    </div>
    <div style="height: 60vh">
      <DataTable
        :value="businessList"
        :filters.sync="filters"
        filterDisplay="row"
        :loading="loading"
        class="p-datatable-sm"
        :sortOrder="1"
        sortField="classification.nameAlias"
        responsiveLayout="scroll"
        removableSort
        sortMode="single"
        :scrollable="true"
        scrollHeight="flex"
        :selection.sync="selectedBusiness"
      >
        <template #empty> Não existem Negócios. </template>
        <template #loading> A carregar. Por favor Aguarde... </template>
        <template #header>
          <div class="table-header">
            <Button
              v-if="can('hideBusinessClassification')"
              icon="pi pi-plus"
              class="p-button-sm p-mr-2"
              label="Criar Classificação"
              @click="showCreateForm = true"
            />
            <Button
              v-if="
                can('hideBusinessClassification') &&
                currentIndicator === 'Negócios por Classificar' &&
                selectedBusiness &&
                selectedBusiness.length > 0
              "
              icon="pi pi-arrows-h"
              class="p-button-sm"
              label="Associar Negócios a Classificação"
              @click="showAssociateForm = true"
            />
          </div>
        </template>
        <Column
          v-if="currentIndicator === 'Negócios por Classificar'"
          selectionMode="multiple"
          :headerStyle="{ 'min-width': '3%', 'max-width': '3%' }"
          :filterHeaderStyle="{ 'min-width': '3%', 'max-width': '3%' }"
          :bodyStyle="{ 'min-width': '3%', 'max-width': '3%' }"
        ></Column>
        <Column
          v-if="currentIndicator === 'Negócios por Classificar'"
          sortable
          field="alias"
          header="Negócio"
          filterField="alias"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          v-if="currentIndicator === 'Negócios por Classificar'"
          sortable
          field="sale_date"
          header="Data Venda"
          filterField="sale_date"
          dataType="sale_date"
          :bodyStyle="{
            'text-align': 'center',
            display: 'table-cell',
          }"
          :headerClass="'align-center'"
        >
          <template #body="slotProps">
            <span>{{ getDate(slotProps.data.sale_date) }}</span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <v-date-picker
              v-model="filterModel.value"
              @input="filterCallback()"
              :masks="{
                input: 'YYYY-MM-DD',
              }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="p-column-filter p-inputtext p-component"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
          </template>
        </Column>
        <Column
          v-if="currentIndicator !== 'Negócios por Classificar'"
          sortable
          field="marketing_name"
          header="Nome Classificação"
          filterField="marketing_name"
        >
          <template #body="slotProps">
            <span
              class="clickable-item"
              @click="goToClassification(slotProps.data.id)"
              >{{ slotProps.data.marketing_name }}</span
            >
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          sortable
          field="seller_name"
          header="Vendedor"
          filterField="seller_name"
          :bodyStyle="{
            'text-align': 'center',
            display: 'table-cell',
          }"
          :headerClass="'align-center'"
        >
          <template #body="slotProps">
            {{ slotProps.data.seller_name }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          v-if="currentIndicator !== 'Negócios por Classificar'"
          sortable
          field="case_study"
          header="Caso de Estudo"
          filterField="case_study"
          :bodyStyle="{
            'text-align': 'center',
            display: 'table-cell',
          }"
          :headerClass="'align-center'"
        >
          <template #body="slotProps">
            <span>{{ slotProps.data.case_study == 1 ? "Sim" : "Não" }}</span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              v-model="filterModel.value"
              @input="filterCallback()"
              :options="[
                { value: 0, label: 'Não' },
                { value: 1, label: 'Sim' },
              ]"
              optionValue="value"
              optionLabel="label"
              class="p-column-filter"
              :showClear="true"
              :style="{
                width: '80%',
              }"
            >
            </Dropdown>
          </template>
        </Column>
        <Column
          v-if="currentIndicator !== 'Negócios por Classificar'"
          sortable
          field="closed"
          header="Edição Finalizada"
          filterField="closed"
          :bodyStyle="{
            'text-align': 'center',
            display: 'table-cell',
          }"
          :headerClass="'align-center'"
        >
          <template #body="slotProps">
            <span>{{ slotProps.data.closed == 1 ? "Sim" : "Não" }}</span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              v-model="filterModel.value"
              @input="filterCallback()"
              :options="[
                { value: 0, label: 'Não' },
                { value: 1, label: 'Sim' },
              ]"
              optionValue="value"
              optionLabel="label"
              class="p-column-filter"
              :showClear="true"
              :style="{
                width: '80%',
              }"
            >
            </Dropdown>
          </template>
        </Column>
        <Column
          v-if="currentIndicator !== 'Negócios por Classificar'"
          sortable
          field="lastUpdate"
          header="Atualizado"
          filterField="lastUpdate"
          dataType="date"
          :bodyStyle="{
            'text-align': 'center',
            display: 'table-cell',
          }"
          :headerClass="'align-center'"
        >
          <template #body="slotProps">
            <span>{{ slotProps.data.updated_at }} </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <v-date-picker
              v-model="filterModel.value"
              @input="filterCallback()"
              :masks="{
                input: 'YYYY-MM-DD',
              }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="p-column-filter p-inputtext p-component"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api/";
import { getOnlyDate, getOnlyTime } from "../helpers/helpers";
import businessService from "../services/business.service";
import ClassificationForm from "../components/business/ClassificationForm.vue";
import AddBusinessToClassification from "../components/business/AddBusinessToClassification.vue";
import W4MstreakIndicators from "../components/W4Mstreak/W4MstreakIndicators.vue";
export default {
  name: "BusinessClassification",
  components: {
    ClassificationForm,
    W4MstreakIndicators,
    AddBusinessToClassification,
  },
  data() {
    return {
      loading: false,
      showAssociateForm: false,
      showCreateForm: false,
      indicatorLoading: false,
      businessList: null,
      streakIndicators: [],
      selectedBusiness: null,
      currentIndicator: null,
      filters: {},
    };
  },
  async created() {
    this.initFilters();
    this.loading = true;
    this.initStreakIndicators();
    return (this.loading = false);
  },
  methods: {
    initFilters() {
      return this.filters ={
        alias: { value: null, matchMode: FilterMatchMode.CONTAINS },
        marketing_name: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        seller_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        sale_date: { value: null, matchMode: FilterMatchMode.DATE_IS },
        date: { value: null, matchMode: FilterMatchMode.DATE_IS },
        closed: {
          value: null,
          matchMode: FilterMatchMode.EQUALS,
        },
        case_study: {
          value: null,
          matchMode: FilterMatchMode.EQUALS,
        },
        lastUpdate: {
          value: null,
          matchMode: FilterMatchMode.DATE_IS,
        },
      }
    },
    async initStreakIndicators() {
      this.indicatorLoading = true;
      this.streakIndicators = [];
      if (this.can("hideBusinessClassification")) {
        this.streakIndicators.push({
          title: "Negócios por Classificar",
          counter: await businessService.countBusinesToClassify(),
          color: "#ff9900",
        });
      }
      this.streakIndicators.push({
        title: "Classificações de Negócios",
        counter: await businessService.countClassifications(),
        color: "#05891d",
      });
      this.streakIndicators.push({
        title: "Por Finalizar Edição",
        counter: await businessService.countBusinesToClose(),
        color: "#f4d909",
      });
      this.streakIndicators.push({
        title: "Portfólio",
        counter: await businessService.countBusinesDisclose(),
        color: "#00cc6c",
      });
      this.streakIndicators.push({
        title: "Caso de Estudo",
        counter: await businessService.countBusinesCaseStudy(),
        color: "#3f89ff",
      });
      this.indicatorLoading = false;
    },
    getDate(date) {
      return `${getOnlyDate(date)}`;
    },
    getDateTime(date) {
      return `${getOnlyDate(date)} ${getOnlyTime(date)}`;
    },
    addBusinessToClassification(classificationId) {
      this.loading = true;
      let businessIds = [];
      this.selectedBusiness.map((business) => {
        businessIds.push(business.reference);
      });
      this.selectedBusiness = [];
      return businessService.addBusinessToClassification(classificationId, {
        business: businessIds,
      });
    },
    async createClassificationOk(response) {
      if (response.status == "ok") {
        this.loading = true;
        if (
          this.currentIndicator == "Negócios por Classificar" &&
          this.selectedBusiness &&
          this.selectedBusiness.length > 0
        ) {
          await this.addBusinessToClassification(response.result.id);
        }
        this.showCreateForm = false;
        await this.initStreakIndicators();
        await this.streakIndicatorsClick({
          indicatorName: this.currentIndicator,
        });
        return (this.loading = false);
      }
    },
    async selectedClassification(classificationId) {
      this.loading = true;
      await this.addBusinessToClassification(classificationId);
      this.showAssociateForm = false;
      await this.initStreakIndicators();
      await this.streakIndicatorsClick({
        indicatorName: this.currentIndicator,
      });
      return (this.loading = false);
    },
    async streakIndicatorsClick(indicator) {
      this.initFilters();
      this.loading = true;
      this.currentIndicator = indicator.indicatorName;
      this.businessList = [];
      if (this.currentIndicator == "Negócios por Classificar") {
        this.businessList = this.formatClassification(
          await businessService.getBusinesToClassify()
        );
      } else if (this.currentIndicator == "Portfólio") {
        this.businessList = this.formatClassification(
          await businessService.getBusinesDisclose()
        );
      } else if (this.currentIndicator == "Não Divulgar") {
        this.businessList = this.formatClassification(
          await businessService.getBusinesDoNotDisclose()
        );
      } else if (this.currentIndicator == "Ocultos") {
        this.businessList = this.formatClassification(
          await businessService.getBusinesHide()
        );
      } else if (this.currentIndicator == "Caso de Estudo") {
        this.businessList = this.formatClassification(
          await businessService.getBusinesCaseStudy()
        );
      } else if (this.currentIndicator == "Por Finalizar Edição") {
        this.businessList = this.formatClassification(
          await businessService.getBusinesToClose()
        );
      } else if (this.currentIndicator == "Classificações de Negócios") {
        this.businessList = this.formatClassification(
          await businessService.getClassificationList()
        );
      } else {
        this.businessList = this.allBusiness;
      }
      return (this.loading = false);
    },
    formatClassification(classificaton) {
      let response = [];
      classificaton.map((solution) => {
        if (this.currentIndicator === "Negócios por Classificar") {
          solution.date = new Date(`${solution.date}`);
          solution.sale_date = new Date(`${solution.sale_date}`);
          solution.alias = `${solution.name} | ${solution.resume}`;
        } else {
          solution.lastUpdate = new Date(`${solution.updated_at}`);
          solution.seller_name = solution.seller ? solution.seller.username : '';
          if (solution.case_study == null) {
            solution.case_study = 0;
          }
        }
        response.push(solution);
      });
      return response;
    },
    goToClassification(id) {
      let route = this.$router.resolve({
        path: `/business/classification/${id}`,
      });
      window.open(route.href, "_blank");
    },
  },
};
</script>
<style>
.align-center .p-column-header-content {
  justify-content: center !important;
}
th.align-center {
  justify-content: center !important;
}
</style>
