<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '50vw' }"
      :header="`Detalhes das Intervenções`"
      :modal="true"
      :closable="false"
    >
      <div v-if="data != null">
        <Divider align="center">
          <h5><b>Dia Anterior</b></h5>
        </Divider>
        <p>
          No dia anterior, {{ data.tasksDayBefore.day }}, o técnico trabalhou na
          parte da <b>Manhã {{ data.tasksDayBeforeWork.morning }}</b> horas e na
          parte da <b>Tarde {{ data.tasksDayBeforeWork.afternoon }}</b> horas.
          <br /><small
            ><i
              >Estas horas apresentadas não contemplam horas exttras do dia
              anterior.</i
            ></small
          >
        </p>
        <p class="text-red" v-if="data.tasksDayBefore.inVacation">
          No dia Anterior o técnico encontrava-se de férias
        </p>
        <p class="text-red" v-if="data.tasksDayBefore.missed">
          No dia Anterior o técnico faltou
        </p>

        <H5 v-if="data.tasksDayBefore.tasks.length > 0"
          >Todas as Intervenções do Dia:</H5
        >
        <span
          v-for="task in data.tasksDayBefore.tasks"
          :key="task.id"
          class="p-d-flex p-flex-column"
        >
          <divider />
          <span class="p-d-flex p-flex-column">
            <span><b>PAT:</b> {{ task.pat_number }}</span>
            <span
              ><b>Projeto|Entidade:</b> {{ task.project_name }} |
              {{ task.entity_name }}</span
            >
            <span><b>Inicio:</b> {{ getTime(task.start) }}</span>
            <span><b>Fim:</b> {{ getTime(task.end) }}</span>
            <span><b>Trabalho Efetuado:</b> {{ task.resume }}</span>
          </span>
        </span>
        <Divider align="center">
          <h5><b>Dia Seguinte</b></h5>
        </Divider>
        <p>
          No dia seguinte, {{ data.tasksDayAfter.day }}, o técnico trabalhou na
          parte da <b>Manhã {{ data.tasksDayAfterWork.morning }}</b> horas e na
          parte da <b>Tarde {{ data.tasksDayAfterWork.afternoon }}</b> horas.
          <br /><small
            ><i
              >Estas horas apresentadas não contemplam horas exttras do dia
              anterior.</i
            ></small
          >
        </p>
        <p class="text-red" v-if="data.tasksDayAfter.inVacation">
          No dia Anterior o técnico encontrava-se de férias
        </p>
        <p class="text-red" v-if="data.tasksDayAfter.missed">
          No dia Anterior o técnico faltou
        </p>

        <H5 v-if="data.tasksDayAfter.tasks.length > 0"
          >Todas as Intervenções do Dia:</H5
        >
        <span
          v-for="task in data.tasksDayAfter.tasks"
          :key="task.id"
          class="p-d-flex p-flex-column"
        >
          <divider />
          <span class="p-d-flex p-flex-column">
            <span><b>PAT:</b> {{ task.pat_number }}</span>
            <span
              ><b>Projeto|Entidade:</b> {{ task.project_name }} |
              {{ task.entity_name }}</span
            >
            <span><b>Inicio:</b> {{ getTime(task.start) }}</span>
            <span><b>Fim:</b> {{ getTime(task.end) }}</span>
            <span><b>Trabalho Efetuado:</b> {{ task.resume }}</span>
          </span>
        </span>
      </div>
      <template #footer>
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import { getOnlyTime } from "../../helpers/helpers";
export default {
  name: "InterventionDetails",
  props: ["show", "info"],
  watch: {
    show() {
      if (this.show == true) {
        this.data = this.info;
      } else {
        this.$validator.pause();
        this.resetData();
      }
    },
  },
  data() {
    return {
      data: null,
    };
  },
  methods: {
    resetData() {
      return (this.data = null);
    },
    cancel() {
      this.resetData;
      return this.$emit("close");
    },
    getTime(date) {
      return getOnlyTime(new Date(date));
    },
  },
};
</script>
<style lang="css" scoped>
.bg-red {
  background-color: rgba(235, 127, 127, 0.973);
}
.text-red {
  color: red;
}
</style>
