<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '50vw' }"
      :header="processing ? `Processar Horas Extras` : `Approvar Horas Extras`"
      :modal="true"
      :closable="false"
    >
      <form
        @submit.prevent="handleSubmit"
        style="min-height: 15vh"
        class="p-mt-3"
      >
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-4">
            <span class="p-float-label">
              <InputText
                name="technical"
                :disabled="true"
                v-model="data.technical.username"
                v-bind:class="['form-control']"
              />
              <label for="technical">Técnico</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-2">
            <span class="p-float-label">
              <InputText
                name="day"
                :disabled="true"
                v-model="data.date"
                v-bind:class="['form-control']"
              />
              <label for="day">Dia</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-2">
            <span class="p-float-label">
              <InputText
                name="start"
                :disabled="true"
                :value="!inApprove ? data.start_edited : data.start"
                v-bind:class="['form-control']"
              />
              <label for="start">Hora de Inicio</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-2">
            <span class="p-float-label">
              <InputText
                name="end"
                :disabled="true"
                :value="!inApprove ? data.end_edited : data.end"
                v-bind:class="['form-control']"
              />
              <label for="end">Hora de Fim</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-2">
            <span class="p-float-label">
              <InputText
                name="extraHours"
                :disabled="true"
                :value="!inApprove ? data.extra_hours_edited : data.extra_hours"
                v-bind:class="['form-control']"
              />
              <label for="extraHours">Horas Extras</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-4 p-mt-3">
            <span class="p-float-label">
              <InputText
                name="type"
                :disabled="true"
                v-model="data.type"
                v-bind:class="['form-control']"
              />
              <label for="type">Tipo de Horas Extras</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-8 p-mt-3">
            <span class="p-float-label">
              <InputText
                name="interventionInfo"
                :disabled="true"
                v-model="data.interventionInfo"
                v-bind:class="['form-control']"
              />
              <label for="interventionInfo">Pat Info</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-12 p-mt-3">
            <span class="p-float-label">
              <Textarea
                type="text"
                name="resume"
                :autoResize="true"
                rows="1"
                v-model="data.intervention.resume"
                :disabled="true"
              />
              <label for="resume">Trabalho Efetuado</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-12 p-mt-3">
            <span class="p-float-label">
              <Textarea
                type="text"
                name="notes"
                :autoResize="true"
                rows="1"
                v-model="data.intervention.notes"
                :disabled="true"
              />
              <label for="notes">Notas</label>
            </span>
          </div>
          <div
            class="p-field p-col-12 p-md-6"
            v-if="
              data.tasksDayBefore != undefined &&
              data.tasksDayBefore.day != undefined &&
              inApprove
            "
          >
            <span><b>Dia Anterior:</b></span>
            <p>
              No dia anterior, {{ data.tasksDayBefore.day }}, o técnico
              trabalhou na parte da
              <b>Manhã {{ data.tasksDayBeforeWork.morning }}</b> horas e na
              parte da
              <b>Tarde {{ data.tasksDayBeforeWork.afternoon }}</b> horas.
              <br /><small
                ><i
                  >Estas horas apresentadas não contemplam horas exttras do dia
                  anterior.</i
                ></small
              >
            </p>
            <p class="text-red" v-if="data.tasksDayBefore.inVacation">
              No dia Anterior o técnico encontrava-se de férias
            </p>
            <p class="text-red" v-if="data.tasksDayBefore.missed">
              No dia Anterior o técnico faltou
            </p>
            <Divider align="center" v-if="!beforeDetails">
              <Button
                icon="pi pi-plus"
                class="p-button-rounded p-button-text p-button-secondary"
                @click="beforeDetails = true"
              />
            </Divider>
            <span v-if="beforeDetails">
              <H5 v-if="data.tasksDayBefore.tasks.length > 0">Intervenções:</H5>
              <span
                v-for="task in data.tasksDayBefore.tasks"
                :key="task.id"
                class="p-d-flex p-flex-column"
              >
                <divider />
                <span class="p-d-flex p-flex-column">
                  <span><b>PAT:</b> {{ task.pat_number }}</span>
                  <span
                    ><b>Projeto|Entidade:</b> {{ task.project_name }} |
                    {{ task.entity_name }}</span
                  >
                  <span><b>Inicio:</b> {{ getTime(task.start) }}</span>
                  <span><b>Fim:</b> {{ getTime(task.end) }}</span>
                  <span><b>Trabalho Efetuado:</b> {{ task.resume }}</span>
                </span>
              </span>
            </span>
            <Divider align="center" v-if="beforeDetails">
              <Button
                icon="pi pi-minus"
                class="p-button-rounded p-button-text p-button-secondary"
                @click="beforeDetails = false"
              />
            </Divider>
          </div>
          <div
            class="p-field p-col-12 p-md-6"
            v-if="
              data.tasksDayAfter != undefined &&
              data.tasksDayAfter.day != undefined &&
              inApprove
            "
          >
            <span><b>Dia Seguinte</b></span>

            <p>
              No dia seguinte, {{ data.tasksDayAfter.day }}, o técnico trabalhou
              na parte da
              <b>Manhã {{ data.tasksDayAfterWork.morning }}</b> horas e na parte
              da <b>Tarde {{ data.tasksDayAfterWork.afternoon }}</b> horas.
              <br /><small
                ><i
                  >Estas horas apresentadas não contemplam horas exttras do dia
                  anterior.</i
                ></small
              >
            </p>
            <p class="text-red" v-if="data.tasksDayAfter.inVacation">
              No dia Anterior o técnico encontrava-se de férias
            </p>
            <p class="text-red" v-if="data.tasksDayAfter.missed">
              No dia Anterior o técnico faltou
            </p>
            <Divider align="center" v-if="!afterDetails">
              <Button
                icon="pi pi-plus"
                class="p-button-rounded p-button-text p-button-secondary"
                @click="afterDetails = true"
              />
            </Divider>
            <span v-if="afterDetails">
              <H5 v-if="data.tasksDayAfter.tasks.length > 0">Intervenções:</H5>
              <span
                v-for="task in data.tasksDayAfter.tasks"
                :key="task.id"
                class="p-d-flex p-flex-column"
              >
                <divider />
                <span class="p-d-flex p-flex-column">
                  <span><b>PAT:</b> {{ task.pat_number }}</span>
                  <span
                    ><b>Projeto|Entidade:</b> {{ task.project_name }} |
                    {{ task.entity_name }}</span
                  >
                  <span><b>Inicio:</b> {{ getTime(task.start) }}</span>
                  <span><b>Fim:</b> {{ getTime(task.end) }}</span>
                  <span><b>Trabalho Efetuado:</b> {{ task.resume }}</span>
                </span>
              </span>
            </span>
            <Divider align="center" v-if="afterDetails">
              <Button
                icon="pi pi-minus"
                class="p-button-rounded p-button-text p-button-secondary"
                @click="afterDetails = false"
              />
            </Divider>
          </div>
          <div class="p-field p-col-12 p-md-2" v-if="inApprove">
            <span class="p-float-label">
              <Calendar
                id="start_edited"
                name="start_edited"
                v-model="data.start_edited"
                v-validate="
                  inApprove === true ? 'required|date_format:H:mm' : ''
                "
                v-bind:class="[
                  { 'p-error': errors.has('start') },
                  'form-control',
                ]"
                :timeOnly="true"
                :hideOnDateTimeSelect="true"
                :stepMinute="5"
              />
              <label for="inputtext">Ajustar Hora de Início</label>
            </span>

            <small
              v-if="errors.has('start_edited')"
              class="p-error"
              role="alert"
              >Ajuste da hora de inicio é obrigatório</small
            >
          </div>
          <div class="p-field p-col-12 p-md-2" v-if="inApprove">
            <span class="p-float-label">
              <Calendar
                id="end_edited"
                name="end_edited"
                v-model="data.end_edited"
                v-validate="
                  inApprove === true ? 'required|date_format:H:mm' : ''
                "
                v-bind:class="[
                  { 'p-error': errors.has('end_edited') },
                  'form-control',
                ]"
                :timeOnly="true"
                :stepMinute="15"
              />
              <label for="inputtext">Ajustar Hora de Fim</label>
            </span>

            <small v-if="errors.has('end_edited')" class="p-error" role="alert"
              >Ajuste da hora de fim obrigatório</small
            >
          </div>
          <div class="p-field p-col-12 p-md-2" v-if="processing">
            <span class="p-float-label">
              <InputText
                name="extraHoursAjusted"
                :disabled="true"
                v-model="data.extra_hours_edited"
                v-bind:class="['form-control']"
              />
              <label for="extraHoursAjusted">Horas Extras</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-1 p-mt-1">
            <span
              class="p-float-label"
              :style="{ top: '-0.75rem', left: '-0.5rem', fontSize: '12px' }"
            >
              <label for="aproved">Aprovada</label>
            </span>
            <InputSwitch
              v-model="data.aproved"
              :name="'aproved'"
              style="'top: 6px;"
              :disabled="!inApprove"
              @change="aprovedChange()"
            />
          </div>
          <div class="p-field p-col-12 p-md-1 p-mt-1" v-if="processing">
            <span
              class="p-float-label"
              :style="{ top: '-0.75rem', left: '-0.5rem', fontSize: '12px' }"
            >
              <label for="aproved">Processada</label>
            </span>
            <InputSwitch
              v-model="data.processed"
              :name="'processed'"
              style="'top: 6px;"
            />
          </div>
          <div class="p-field p-col-12 p-md-2 p-mt-1" v-if="processing">
            <span
              class="p-float-label"
              :style="{ top: '-0.75rem', left: '-0.5rem', fontSize: '12px' }"
            >
              <label for="aproved">Mês Processamento</label>
            </span>
            <Calendar
              name="processed_month"
              v-model="data.processed_month"
              view="month"
              dateFormat="M yy"
              :yearNavigator="true"
              :disabled="!inApprove"
              v-validate="data.processed ? 'required' : ''"
            />
            <small
              v-if="errors.has('processed_month')"
              class="p-error"
              role="alert"
            >
              Mês do processamento é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-12 p-mt-3">
            <span class="p-float-label">
              <Textarea
                type="text"
                name="reason_rejection"
                :autoResize="true"
                rows="3"
                :disabled="!inApprove || data.aproved === true"
                v-validate="inApprove === true ? 'required' : ''"
                v-model="data.reason_rejection"
                v-bind:class="[
                  { 'p-invalid': errors.has('reason_rejection') },
                  'form-control',
                ]"
              />
              <label for="notes">Razão da Rejeição</label>
            </span>
            <small
              v-if="errors.has('reason_rejection')"
              class="p-error"
              role="alert"
            >
              Razão da Rejeição é obrigatório
            </small>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          v-if="inApprove"
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="handleSubmit"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import extraHoursService from "../../services/extraHours.service";
import { getOnlyTime, getOnlyDate } from "../../helpers/helpers";
export default {
  name: "FormApproveExtraHours",
  props: ["show", "info", "inApprove", "processing"],
  watch: {
    show() {
      if (this.show == true) {
        this.$validator.resume();
        this.data = { ...this.info };
        if (this.data.processed_month == "") {
          this.data.processed_month = null;
        }
      } else {
        this.$validator.pause();
        this.resetData();
      }
    },
  },
  data() {
    return {
      data: {
        intervention: {},
        technical: {},
        tasksDayAfterWork: {},
        tasksDayBeforeWork: {},
      },
      beforeDetails: false,
      afterDetails: false,
    };
  },
  methods: {
    resetData() {
      return (this.data = {
        intervention: {},
        technical: {},
        tasksDayAfterWork: {},
        tasksDayBeforeWork: {},
        beforeDetails: false,
        afterDetails: false,
      });
    },
    aprovedChange() {
      if (this.data.aproved === true) {
        this.data.reason_rejection = "";
      }
    },
    handleSubmit() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          return;
        }
        this.$validator.pause();
        this.$validator.reset();
        let params = {
          approve: this.data.aproved === true ? 1 : 0,
          start_ajusted:
            this.data.start_edited instanceof Date
              ? this.getTime(this.data.start_edited)
              : this.data.start_edited,
          end_ajusted:
            this.data.end_edited instanceof Date
              ? this.getTime(this.data.end_edited)
              : this.data.end_edited,
        };
        if (this.processing === true) {
          return this.setProcessed(params);
        }
        return this.approve(params);
      });
    },
    setProcessed(params) {
      params.processed = this.data.processed === true ? 1 : 0;
      params.processed_month = this.data.processed_month;
      params.processed_month =
        this.data.processed_month instanceof Date
          ? this.getDate(this.data.processed_month)
          : this.data.processed_month;
      if (this.data.aproved === false) {
        params.reason_rejection = this.data.reason_rejection;
      }
      return extraHoursService
        .process(this.data.id, params)
        .then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro Processar Hora Extra",
              detail: "",
              life: 3000,
            });
          }
          this.$emit("extraHourProcessed");
          this.$toast.add({
            severity: "success",
            summary: `Hora Extra processada`,
            detail: ``,
            life: 3000,
          });
          return this.resetData();
        });
    },
    approve(params) {
      if (this.data.aproved === false) {
        params.reason_rejection = this.data.reason_rejection;
      }
      return extraHoursService
        .approve(this.data.id, params)
        .then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro Aprovar Hora Extra",
              detail: "",
              life: 3000,
            });
          }
          this.$emit("extraHourApproved");
          this.$toast.add({
            severity: "success",
            summary: `Hora Extra aprovada`,
            detail: ``,
            life: 3000,
          });
          return this.resetData();
        });
    },
    cancel() {
      this.resetData;
      return this.$emit("cancel");
    },
    getTime(date) {
      return getOnlyTime(new Date(date));
    },
    getDate(date) {
      return getOnlyDate(new Date(date));
    },
  },
};
</script>

<style>
.text-red {
  color: red;
}

.extra-hours-form {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input,
textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

button {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
</style>
