<template>
  <div class="card p-shadow-6">
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <h1>Horas Extras</h1>
    <ExtraHoursForm
      :show="toAprove.show"
      :info="toAprove.data"
      :inApprove="false"
      :processing="false"
      v-on:cancel="cancelled"
    />
    <div class="p-grid p-fluid dashboard p-jc-center">
      <W4Mstreak
        :states="streakStatus"
        :loader="streakLoading"
        v-on:W4MStreakChangeSelected="streakSelectedChange"
      />
    </div>
    <div style="height: 65vh">
      <DataTable
        :value="extraHours != null ? extraHours.data : []"
        :filters.sync="filters"
        filterDisplay="row"
        :loading="loading"
        class="p-datatable-sm"
        responsiveLayout="scroll"
        :scrollable="true"
        scrollHeight="flex"
        :autoLayout="true"
      >
        <template #empty> Não existem horas extas. </template>
        <template #loading> A carregar. Por favor Aguarde... </template>
        <template #header>
          <div class="table-header">
            <div class="p-d-flex">
              <span class="p-mr-2 p-d-flex p-ai-center">
                <label for="start" class="p-mr-1">Inicio</label>
                <MonthPickerInput
                  :min-date="new Date('2024-08-31')"
                  :default-month="startMonth"
                  :default-year="startYear"
                  :editable-year="true"
                  lang="pt"
                  style="width: 100%"
                  @change="changeStart"
                />
              </span>
              <span class="p-mr-2 p-d-flex p-ai-center">
                <label for="end" class="p-mr-1">Fim</label>
                <MonthPickerInput
                  :max-date="new Date()"
                  :default-month="endMonth"
                  :default-year="endYear"
                  :editable-year="true"
                  lang="pt"
                  style="width: 100%"
                  @change="changeEnd"
                />
              </span>
              <span
                class="p-mr-2 p-d-flex p-ai-center"
                v-if="can('seeExtraHoursAll')"
              >
                <label for="technical" class="p-mr-1">Técnico</label>
                <Dropdown
                  name="technical"
                  v-model="selectedEmployee"
                  :options="employeesList"
                  optionLabel="username"
                  optionValue="employeeId"
                  @change="hasChanges = true"
                >
                </Dropdown>
              </span>
            </div>
          </div>
        </template>
        <Column
          v-if="can('seeExtraHoursAll')"
          field="technical.username"
          header="Técnico"
          sortable
          filterField="technical.username"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter p-col-12"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="day"
          header="Data"
          filterField="day"
          dataType="date"
          sortable
        >
          <template #body="slotProps">
            <span class="clickable-item" @click="approveHour(slotProps.data)">
              {{ slotProps.data.date }} ({{ slotProps.data.type }})
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <v-date-picker
              v-model="filterModel.value"
              @input="filterCallback()"
              :masks="{
                input: 'YYYY-MM-DD',
              }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="p-column-filter p-inputtext p-component"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
          </template>
        </Column>
        <Column field="start_edited" header="Inicio" />
        <Column field="end_edited" header="Inicio" />
        <Column field="extra_hours_edited" header="Horas Extras" />
        <Column field="interventionInfo" header="" />
        <Column
          field="reason_rejection"
          header="Razão Rejeição"
          v-if="currentState.currentState == 'Rejeitadas'"
        />
      </DataTable>
    </div>
    <Paginator
      v-if="
        extraHours != null &&
        extraHours.data != undefined &&
        extraHours.data.length > 0
      "
      :rows="extraHours.per_page"
      :totalRecords="extraHours.total"
      template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
      :currentPageReportTemplate="'{currentPage} de {totalPages}'"
      @page="paginatorChange"
    ></Paginator>
  </div>
</template>

<script>
import paginatorService from "../services/paginator.service";
import extraHoursService from "../services/extraHours.service";
import employeeService from "../services/employee.service";
import ExtraHoursForm from "../components/extraHours/Form.vue";
import { FilterMatchMode } from "primevue/api/";
// import { getOnlyDate } from "../helpers/helpers";
import { MonthPickerInput } from "vue-month-picker";
import W4Mstreak from "../components/W4Mstreak/W4Mstreak.vue";

export default {
  name: "ExtraHours",
  components: {
    ExtraHoursForm,
    MonthPickerInput,
    W4Mstreak,
  },
  data() {
    return {
      hasChanges: false,
      loading: false,
      extraHours: null,
      streakStatus: [],
      streakLoading: false,
      currentState: {},
      filters: {
        "technical.username": {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        day: { value: null, matchMode: FilterMatchMode.DATE_IS },
      },
      toAprove: {
        show: false,
      },
      startMonth: null,
      startYear: null,
      endMonth: null,
      endYear: null,
      selectedEmployee: null,
      employeesList: [],
    };
  },
  computed: {
    loggedUser() {
      return this.$store.state.auth.user;
    },
  },
  watch: {
    hasChanges() {
      if (this.hasChanges === true) {
        return this.initStreak();
      }
    },
  },
  async created() {
    this.loading = true;
    // let endDate = new Date();
    // this.endMonth = endDate.getMonth() + 1;
    // this.endYear = endDate.getFullYear();

    let startDate = new Date();
    startDate.setMonth(startDate.getMonth() - 1);
    this.startYear = startDate.getFullYear();
    this.endYear = startDate.getFullYear();
    if (this.startYear == "2024" && startDate.getMonth() < 12) {
      this.startMonth = 12;
      this.endMonth = 12;
    } else {
      this.startMonth = startDate.getMonth();
      this.endMonth = startDate.getMonth();
    }

    this.selectedEmployee = this.loggedUser.employeeId;
    if (this.can("seeExtraHoursAll")) {
      this.employeesList = await employeeService.getExtraHoursEmployees();
    }
    await this.initStreak();
    return (this.loading = false);
  },
  methods: {
    format(extraHours) {
      let formated = extraHours.map((v) => ({
        ...v,
        day: new Date(v.date),
        interventionInfo: `Pat ${v.intervention.pat} | ${v.intervention.name} | ${v.intervention.project}`,
      }));
      return formated;
    },
    changeStart(value) {
      this.startMonth = value.monthIndex;
      this.startYear = value.year;
      if (this.extraHours == null) {
        return;
      }
      return (this.hasChanges = true);
    },
    changeEnd(value) {
      this.endMonth = value.monthIndex;
      this.endYear = value.year;
      if (this.extraHours == null) {
        return;
      }
      return (this.hasChanges = true);
    },
    paginatorChange(event) {
      this.loading = true;
      let params = this.getParamsfromFilter();
      return paginatorService
        .getItemsWithFilters(this.extraHours.links[event.page + 1].url, params)
        .then((response) => {
          this.extraHours = response;
          this.extraHours.data = this.format(response.data);
          return (this.loading = false);
        });
    },
    cancelled() {
      this.toAprove = { show: false };
    },
    approveHour(data) {
      this.toAprove.data = data;
      return (this.toAprove.show = true);
    },
    getParams(status) {
      let parseStatus = -1;
      if (status === "Rejeitadas") {
        parseStatus = 0;
      } else if (status === "Processadas") {
        parseStatus = 1;
      } else if (status === "Por Processar") {
        parseStatus = 2;
      }

      return {
        start: `${this.startYear}-${this.startMonth < 10 ? "0" : ""}${
          this.startMonth
        }`,
        end: `${this.endYear}-${this.endMonth < 10 ? "0" : ""}${this.endMonth}`,
        type: parseStatus,
      };
    },
    async initStreak() {
      this.extraHours = [];
      this.hasChanges = false;
      this.streakLoading = true;
      this.streakStatus = [
        {
          backgroudColor: "#00C8B6",
          textColor: "#000",
          title: "Processadas",
          counter: "00:00",
          active: false,
        },
      ];
      if (this.can("approveExtraHours")) {
        this.streakStatus.push({
          backgroudColor: "#dcf900",
          textColor: "#000",
          title: "Por Aprovar",
          counter: "00:00",
          active: false,
        });
        this.streakStatus.push({
          backgroudColor: "#e03d23",
          textColor: "#000",
          title: "Rejeitadas",
          counter: "00:00",
          active: false,
        });
      }

      if (this.can("processExtraHours")) {
        this.streakStatus.push({
          backgroudColor: "#f24f00",
          textColor: "#000",
          title: "Por Processar",
          counter: "00:00",
          active: false,
        });
      }
      this.streakStatus = [
        {
          backgroudColor: "#00C8B6",
          textColor: "#000",
          title: "Processadas",
          counter: (
            await extraHoursService.coutTechnicalExtraHours(
              this.selectedEmployee,
              this.getParams("Processadas")
            )
          ).time,
          active: false,
        },
      ];
      if (this.can("approveExtraHours")) {
        this.streakStatus.push({
          backgroudColor: "#dcf900",
          textColor: "#000",
          title: "Por Aprovar",
          counter: (
            await extraHoursService.coutTechnicalExtraHours(
              this.selectedEmployee,
              this.getParams("Por Aprovar")
            )
          ).time,
          active: false,
        });
        this.streakStatus.push({
          backgroudColor: "#e03d23",
          textColor: "#000",
          title: "Rejeitadas",
          counter: (
            await extraHoursService.coutTechnicalExtraHours(
              this.selectedEmployee,
              this.getParams("Rejeitadas")
            )
          ).time,
          active: false,
        });
      }

      if (this.can("processExtraHours")) {
        this.streakStatus.push({
          backgroudColor: "#f24f00",
          textColor: "#000",
          title: "Por Processar",
          counter: (
            await extraHoursService.coutTechnicalExtraHours(
              this.selectedEmployee,
              this.getParams("Por Processar")
            )
          ).time,
          active: false,
        });
      }

      return (this.streakLoading = false);
    },
    async streakSelectedChange(selected) {
      this.currentState = selected;
      this.loading = true;
      this.extraHours = [];
      this.extraHours = await extraHoursService
        .getTechnicalExtraHours(
          this.selectedEmployee,
          this.getParams(this.currentState.currentState)
        )
        .then((response) => {
          response.data = this.format(response.data);
          this.loading = false;
          return response;
        });
    },
  },
};
</script>
<style>
.month-picker-input {
  height: 33px;
  color: #495057;
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
month-picker-input-container {
  width: 100% !important;
}
.month-picker__container {
  z-index: 50;
}
</style>
